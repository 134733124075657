import React from 'react';
import {CRUDType, DataActionType, Field} from '../components/Data/DataConst';
import {DataSubType, DataType} from '../const/crud';

export enum ModalType {
  SUCCESS = 'success',
  ERROR = 'error',
  CANCEL_PROMPT = 'cancel-prompt',
  DELETE_PROMPT = 'delete-prompt',
  ADD_NOTE = 'add-note',
  DELETE_NOTE_PROMPT = 'delete-note-prompt',
  CREATE_NOTE = 'create-note',
  CREATE_MEDIA = 'create-media',
  DELETE_MEDIA_PROMPT = 'delete-media-prompt',
  CREATE_ATTACHMENT = 'create-attachment',
  DELETE_ATTACHMENT_PROMPT = 'delete-attachment-prompt',
  CREATE_LOCATION = 'create-location',
  DELETE_LOCATION_PROMPT = 'delete-location-prompt',
  WARNING = 'warning',
  SUBSCRIPTION_EXPIRED = 'subscription-expired',
  CREATE_CONTACT = 'add-contact',
  EDIT_CONTACT = 'edit-contact',
  DELETE_CONTACT_PROMPT = 'delete-contact-prompt',
  NOT_PERMITTED = 'not-permitted',
  CREATE_CHECKLIST = 'create-checklist',
  EDIT_CHECKLIST = 'edit-checklist',
  DELETE_CHECKLIST_PROMPT = 'delete-checklist-prompt',
  CREATE_CHECKLIST_TEMPLATE = 'create-checklist-template',
  EDIT_CHECKLIST_TEMPLATE = 'edit-checklist-template',
  DELETE_CHECKLIST_TEMPLATE_PROMPT = 'delete-checklist-template-prompt',
  IMPORT_CHECKLIST = 'import-checklist',
  IMPORT_XERO_CUSTOMER = 'import-xero-customer',
  IMPORT_QUICKBOOKS_CUSTOMER = 'import-quickbooks-customer',
  CREATE_PAYMENT = 'create-payment',
  EDIT_PAYMENT = 'edit-payment',
  DELETE_PAYMENT_PROMPT = 'delete-payment-prompt',
  CREATE_COST_GROUP = 'create-cost-group',
  EDIT_COST_GROUP = 'edit-cost-group',
  DELETE_COST_GROUP_PROMPT = 'delete-cost-group-prompt',
  IMPORT_COST_GROUP = 'import-cost-group',
  CREATE_COST = 'create-cost',
  EDIT_COST = 'edit-cost',
  DELETE_COST_PROMPT = 'delete-cost-prompt',
  CREATE_JOB_CODE = 'create-job-code',
  EDIT_JOB_CODE = 'edit-job-code',
  DELETE_JOB_CODE_PROMPT = 'delete-job-code-prompt',
  CREATE_THEME = 'create-theme',
  DELETE_THEME_PROMPT = 'delete-theme-prompt',
  CREATE_LEAD_SOURCE = 'create-lead-source',
  EDIT_LEAD_SOURCE = 'edit-lead-source',
  DELETE_LEAD_SOURCE_PROMPT = 'delete-lead-source-prompt',
  CREATE_STAFF_AVAILABILITY = 'create-staff-availability',
  EDIT_STAFF_AVAILABILITY = 'edit-staff-availability',
  DELETE_STAFF_AVAILABILITY = 'delete-staff-availability',
  CREATE_CUSTOM_FIELD = 'create-custom-field',
  EDIT_CUSTOM_FIELD = 'edit-custom-field',
  DELETE_CUSTOM_FIELD_PROMPT = 'delete-custom-field',
}

export enum ModalContentType {
  TITLE = 'title',
  BODY = 'body',
  ACTION_TITLE = 'action-title',
}

export interface ModalParams {
  status: boolean;
  showDataForm?: {
    status: boolean;
    formType?: CRUDType | DataActionType;
  };
  title?: string;
  body?: string;
  action?: ModalAction;
  fields?: Field[];
  data?: any;
}

export interface ModalAction {
  title?: string;
  action: {
    type: CRUDType | DataActionType | 'close' | 'link' | 'logout';
    dataType: DataType;
    dataSubType: DataSubType;
    documentId?: string;
    dataId?: string;
    dataSetter?: any;
    data?: any;
    callback?: any;
  };
}

export const showModalInitialState: ModalParams = {
  status: false,
  showDataForm: {
    status: false,
    formType: CRUDType.CREATE,
  },
  title: '',
  body: '',
  action: {
    title: '',
    action: {
      type: 'close',
      documentId: '',
      dataId: '',
      dataType: DataType.COMPANY,
      dataSubType: DataSubType.SELECTED,
    },
  },
};

export class ModalHelper {
  private state;

  constructor(private modalSetter: any, private dataFormSetter: any) {}

  public setState(state) {
    this.state = state;
  }

  public getState() {
    return this.state;
  }

  public showModal(
    modalType: ModalType,
    showDataForm: {
      status: boolean;
      formType?: CRUDType | DataActionType;
    } = {
      status: false,
    },
    title?: string,
    body?: any,
    action?: ModalAction,
    fields?: Field[],
    data?: any,
  ) {
    const params: ModalParams = {
      status: true,
      showDataForm,
      title: title || this.defaultContent(modalType, ModalContentType.TITLE),
      body: body || this.defaultContent(modalType, ModalContentType.BODY),
      fields,
      data,
    };

    params.action = {
      title:
        (action && action.title) ||
        this.defaultContent(modalType, ModalContentType.ACTION_TITLE),
      action: {
        type:
          action && action.action.type
            ? action.action.type
            : modalType === ModalType.NOT_PERMITTED
            ? 'logout'
            : 'close',
        documentId: action && action.action.documentId,
        dataId: action && action.action.dataId,
        dataType: (action && action.action.dataType) || DataType.CUSTOMER,
        dataSubType:
          (action && action.action.dataSubType) || DataSubType.COLLECTION,
        dataSetter: action && action.action.dataSetter,
        data: action && action.action.data,
        callback: action && action.action.callback,
      },
    };

    this.modalSetter(params);
  }

  public hideModal(
    showDataForm: {
      status: boolean;
      formType?: CRUDType | DataActionType;
    } = {status: false},
  ) {
    const params: ModalParams = {
      status: false,
    };
    this.modalSetter(params);

    if (showDataForm.status) {
      this.dataFormSetter({status: true, formType: showDataForm.formType});
    }
  }

  private defaultContent(modalType: ModalType, contentType: ModalContentType) {
    const content = {
      [ModalType.SUCCESS]: {
        [ModalContentType.TITLE]: "That's all done",
        [ModalContentType.BODY]: 'Request completed successfully.',
      },
      [ModalType.ERROR]: {
        [ModalContentType.TITLE]: 'Something went wrong',
        [ModalContentType.BODY]:
          'There was an error completing this request. Please ensure all required fields are completed and try again.',
      },
      [ModalType.CANCEL_PROMPT]: {
        [ModalContentType.TITLE]: 'Cancel Update',
        [ModalContentType.BODY]: 'Are you sure you want to cancel this update? You will lose any changes made.',
        [ModalContentType.ACTION_TITLE]: 'Yes, lose changes',
      },
      [ModalType.DELETE_PROMPT]: {
        [ModalContentType.TITLE]: 'Delete',
        [ModalContentType.BODY]: 'Are you sure you want to delete this item?',
        [ModalContentType.ACTION_TITLE]: 'Delete',
      },
      [ModalType.ADD_NOTE]: {
        [ModalContentType.TITLE]: 'Add Note',
        [ModalContentType.ACTION_TITLE]: 'Add',
      },
      [ModalType.DELETE_NOTE_PROMPT]: {
        [ModalContentType.TITLE]: 'Delete Note',
        [ModalContentType.BODY]: 'Are you sure you want to delete this note?',
        [ModalContentType.ACTION_TITLE]: 'Delete',
      },
      [ModalType.CREATE_NOTE]: {
        [ModalContentType.TITLE]: 'Create Note',
        [ModalContentType.ACTION_TITLE]: 'Create',
      },
      [ModalType.CREATE_MEDIA]: {
        [ModalContentType.TITLE]: 'Create Media',
        [ModalContentType.ACTION_TITLE]: 'Create',
      },
      [ModalType.DELETE_MEDIA_PROMPT]: {
        [ModalContentType.TITLE]: 'Delete Media',
        [ModalContentType.BODY]: 'Are you sure you want to delete this media?',
        [ModalContentType.ACTION_TITLE]: 'Delete',
      },
      [ModalType.CREATE_ATTACHMENT]: {
        [ModalContentType.TITLE]: 'Create Attachment',
        [ModalContentType.ACTION_TITLE]: 'Create',
      },
      [ModalType.DELETE_ATTACHMENT_PROMPT]: {
        [ModalContentType.TITLE]: 'Delete Attachment',
        [ModalContentType.BODY]:
          'Are you sure you want to delete this attachment?',
        [ModalContentType.ACTION_TITLE]: 'Delete',
      },
      [ModalType.CREATE_LOCATION]: {
        [ModalContentType.TITLE]: 'Create Location',
        [ModalContentType.ACTION_TITLE]: 'Create',
      },
      [ModalType.DELETE_LOCATION_PROMPT]: {
        [ModalContentType.TITLE]: 'Delete Location',
        [ModalContentType.BODY]:
          'Are you sure you want to delete this location?',
        [ModalContentType.ACTION_TITLE]: 'Delete',
      },
      [ModalType.SUBSCRIPTION_EXPIRED]: {
        [ModalContentType.TITLE]: 'Subscription Expired',
        [ModalContentType.BODY]: (
          <div>
            <p>Your subscription to Onsite 7 has expired.</p>
            <p>
              Please contact your company administrator or{' '}
              <a href={'https://onsite7.co.uk/my-account'}>
                click here to renew your subscription
              </a>
              .
            </p>
          </div>
        ),
        [ModalContentType.ACTION_TITLE]: 'Logout',
      },
      [ModalType.NOT_PERMITTED]: {
        [ModalContentType.TITLE]: 'Not Permitted',
        [ModalContentType.BODY]: (
          <div>
            <p>Only Admins can access the Onsite7 dashboard.</p>
            <p>
              Please contact your company administrator if you require access.
            </p>
          </div>
        ),
        [ModalContentType.ACTION_TITLE]: 'Logout',
      },
      [ModalType.CREATE_CONTACT]: {
        [ModalContentType.TITLE]: 'Create Contact',
        [ModalContentType.ACTION_TITLE]: 'Create',
      },
      [ModalType.EDIT_CONTACT]: {
        [ModalContentType.TITLE]: 'Edit Contact',
        [ModalContentType.ACTION_TITLE]: 'Edit',
      },
      [ModalType.DELETE_CONTACT_PROMPT]: {
        [ModalContentType.TITLE]: 'Delete Contact',
        [ModalContentType.BODY]:
          'Are you sure you want to delete this Contact?',
        [ModalContentType.ACTION_TITLE]: 'Delete',
      },
      [ModalType.CREATE_CHECKLIST]: {
        [ModalContentType.TITLE]: 'Create Task',
        [ModalContentType.ACTION_TITLE]: 'Create',
      },
      [ModalType.EDIT_CHECKLIST]: {
        [ModalContentType.TITLE]: 'Edit Task',
        [ModalContentType.ACTION_TITLE]: 'Edit',
      },
      [ModalType.DELETE_CHECKLIST_PROMPT]: {
        [ModalContentType.TITLE]: 'Delete Task',
        [ModalContentType.BODY]: 'Are you sure you want to delete this Task?',
        [ModalContentType.ACTION_TITLE]: 'Delete',
      },
      [ModalType.CREATE_CHECKLIST_TEMPLATE]: {
        [ModalContentType.TITLE]: 'Create Checklist',
        [ModalContentType.ACTION_TITLE]: 'Create',
      },
      [ModalType.EDIT_CHECKLIST_TEMPLATE]: {
        [ModalContentType.TITLE]: 'Edit Checklist',
        [ModalContentType.ACTION_TITLE]: 'Edit',
      },
      [ModalType.DELETE_CHECKLIST_TEMPLATE_PROMPT]: {
        [ModalContentType.TITLE]: 'Delete Checklist',
        [ModalContentType.BODY]:
          'Are you sure you want to delete this Checklist?',
        [ModalContentType.ACTION_TITLE]: 'Delete',
      },
      [ModalType.IMPORT_CHECKLIST]: {
        [ModalContentType.TITLE]: 'Import Checklist',
        [ModalContentType.BODY]:
          'Are you sure you want to import this Checklist?',
        [ModalContentType.ACTION_TITLE]: 'Import',
      },
      [ModalType.IMPORT_XERO_CUSTOMER]: {
        [ModalContentType.TITLE]: 'Import Xero Customer',
        [ModalContentType.BODY]:
          'Are you sure you want to import this customer from Xero?',
        [ModalContentType.ACTION_TITLE]: 'Import',
      },
      [ModalType.IMPORT_QUICKBOOKS_CUSTOMER]: {
        [ModalContentType.TITLE]: 'Import Quickbooks Customer',
        [ModalContentType.BODY]:
          'Are you sure you want to import this customer from Quickbooks?',
        [ModalContentType.ACTION_TITLE]: 'Import',
      },
      [ModalType.CREATE_PAYMENT]: {
        [ModalContentType.TITLE]: 'Create Payment',
        [ModalContentType.BODY]:
          'Create a payment for this job. If you have an active accountancy add-on subscription, an invoice will be automatically created.',
        [ModalContentType.ACTION_TITLE]: 'Create',
      },
      [ModalType.EDIT_PAYMENT]: {
        [ModalContentType.TITLE]: 'Edit Payment',
        [ModalContentType.BODY]: 'Edit an existing payment for this job.',
        [ModalContentType.ACTION_TITLE]: 'Edit',
      },
      [ModalType.DELETE_PAYMENT_PROMPT]: {
        [ModalContentType.TITLE]: 'Delete Payment',
        [ModalContentType.BODY]:
          'Are you sure you want to delete this Payment?',
        [ModalContentType.ACTION_TITLE]: 'Delete',
      },
      [ModalType.CREATE_COST_GROUP]: {
        [ModalContentType.TITLE]: 'Create Cost Group',
        [ModalContentType.ACTION_TITLE]: 'Create',
      },
      [ModalType.EDIT_COST_GROUP]: {
        [ModalContentType.TITLE]: 'Edit Cost Group',
        [ModalContentType.ACTION_TITLE]: 'Edit',
      },
      [ModalType.DELETE_COST_GROUP_PROMPT]: {
        [ModalContentType.TITLE]: 'Delete Cost Group',
        [ModalContentType.BODY]:
          'Are you sure you want to delete this Cost Group?',
        [ModalContentType.ACTION_TITLE]: 'Delete',
      },
      [ModalType.IMPORT_COST_GROUP]: {
        [ModalContentType.TITLE]: 'Import Cost Group',
        [ModalContentType.BODY]:
          'Are you sure you want to import this Cost Group?',
        [ModalContentType.ACTION_TITLE]: 'Import',
      },
      [ModalType.CREATE_COST]: {
        [ModalContentType.TITLE]: 'Create Cost',
        [ModalContentType.ACTION_TITLE]: 'Create',
      },
      [ModalType.EDIT_COST]: {
        [ModalContentType.TITLE]: 'Edit Cost',
        [ModalContentType.ACTION_TITLE]: 'Edit',
      },
      [ModalType.DELETE_COST_PROMPT]: {
        [ModalContentType.TITLE]: 'Delete Cost',
        [ModalContentType.BODY]: 'Are you sure you want to delete this Cost?',
        [ModalContentType.ACTION_TITLE]: 'Delete',
      },
      [ModalType.CREATE_JOB_CODE]: {
        [ModalContentType.TITLE]: 'Create Job ID Prefix',
        [ModalContentType.ACTION_TITLE]: 'Create',
      },
      [ModalType.EDIT_JOB_CODE]: {
        [ModalContentType.TITLE]: 'Edit Job ID Prefix',
        [ModalContentType.ACTION_TITLE]: 'Edit',
      },
      [ModalType.DELETE_JOB_CODE_PROMPT]: {
        [ModalContentType.TITLE]: 'Delete Job ID Prefix',
        [ModalContentType.BODY]:
          'Are you sure you want to delete this Job ID Prefix?',
        [ModalContentType.ACTION_TITLE]: 'Delete',
      },
      [ModalType.CREATE_THEME]: {
        [ModalContentType.TITLE]: 'Create Theme Item',
        [ModalContentType.ACTION_TITLE]: 'Create',
      },
      [ModalType.DELETE_THEME_PROMPT]: {
        [ModalContentType.TITLE]: 'Delete Theme Item',
        [ModalContentType.BODY]:
          'Are you sure you want to delete this theme item?',
        [ModalContentType.ACTION_TITLE]: 'Delete',
      },
      [ModalType.CREATE_LEAD_SOURCE]: {
        [ModalContentType.TITLE]: 'Create Lead Source',
        [ModalContentType.ACTION_TITLE]: 'Create',
      },
      [ModalType.EDIT_LEAD_SOURCE]: {
        [ModalContentType.TITLE]: 'Edit Lead Source',
        [ModalContentType.ACTION_TITLE]: 'Edit',
      },
      [ModalType.DELETE_LEAD_SOURCE_PROMPT]: {
        [ModalContentType.TITLE]: 'Delete Lead Source',
        [ModalContentType.BODY]:
          'Are you sure you want to delete this Lead Source?',
        [ModalContentType.ACTION_TITLE]: 'Delete',
      },
      [ModalType.CREATE_STAFF_AVAILABILITY]: {
        [ModalContentType.TITLE]: 'Create Staff Availability Log',
        [ModalContentType.ACTION_TITLE]: 'Create',
      },
      [ModalType.EDIT_STAFF_AVAILABILITY]: {
        [ModalContentType.TITLE]: 'Edit Staff Availability Log',
        [ModalContentType.ACTION_TITLE]: 'Edit',
      },
      [ModalType.DELETE_STAFF_AVAILABILITY]: {
        [ModalContentType.TITLE]: 'Delete Staff Availability Log',
        [ModalContentType.BODY]:
          'Are you sure you want to delete this Staff Availability Log?',
        [ModalContentType.ACTION_TITLE]: 'Delete',
      },
      [ModalType.CREATE_CUSTOM_FIELD]: {
        [ModalContentType.TITLE]: 'Create Custom Field',
        [ModalContentType.ACTION_TITLE]: 'Create',
      },
      [ModalType.EDIT_CUSTOM_FIELD]: {
        [ModalContentType.TITLE]: 'Edit Custom Field',
        [ModalContentType.ACTION_TITLE]: 'Edit',
      },
      [ModalType.DELETE_CUSTOM_FIELD_PROMPT]: {
        [ModalContentType.TITLE]: 'Delete Custom Field',
        [ModalContentType.BODY]:
          'Are you sure you want to delete this Custom Field?',
        [ModalContentType.ACTION_TITLE]: 'Delete',
      },
    };
    return content[modalType][contentType];
  }
}
