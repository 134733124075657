import {
  BlobProvider,
  Document,
  Font,
  Image,
  Link,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import {startCase} from 'lodash';
import moment from 'moment';
import React, {useState} from 'react';
import {Button} from 'semantic-ui-react';
import {DataType} from '../const/crud';
import {Company, CompanyThemeItemType} from '../models/Company';
import {Customer} from '../models/Customer';
import {Job, JobStage, jobStageMap} from '../models/Job';
import MediaHelper from '../utils/mediaHelper';
import {CRUDType} from './Data/DataConst';

interface InvoicePdfBuilderProps {
  buttonTitle: string;
  buttonTitleView: string;
  data: any;
  loggedInUser: string;
  loggedInCompany: Company;
  action: any;
  customer: Customer;
  contact: any;
  payment: any;
  onChange: any;
}

const latoReg = require('../assets/Lato-Regular.ttf').default;
const latoBold = require('../assets/Lato-Bold.ttf').default;

Font.register({
  family: 'Lato',
  src: '',
  fonts: [
    {
      src: latoReg,
      fontWeight: 'normal',
    },
    {
      src: latoBold,
      fontWeight: 'bold',
    },
  ],
});

const createFilename = (stage: JobStage, customerId: string, stageTitles) =>
  `${jobStageMap(
    stage,
    stageTitles,
  ).toLowerCase()}-completion-report-${customerId}-${moment().format(
    'DD-MM-YYYY-HH-mm-ss',
  )}.pdf`;

const styles = StyleSheet.create({
  logo: {
    backgroundColor: '#ffffff',
    marginVertical: 15,
    marginHorizontal: 150,
  },
  page: {
    flexDirection: 'column',
    paddingVertical: 20,
    paddingHorizontal: 40,
  },
  section: {
    flexDirection: 'column',
    marginVertical: 5,
  },
  fullWidth: {
    justifyContent: 'space-between',
  },
  row: {
    flexDirection: 'row',
  },
  costRow: {
    marginTop: 10,
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  costItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  boxed: {
    padding: 10,
    borderColor: '#dfe4ea',
    borderWidth: 1,
    borderRadius: 5,
  },
  block: {
    marginRight: 20,
  },
  quarter: {
    width: '24.25%',
    marginRight: 10,
  },
  quarterLast: {
    width: '24.25%',
  },
  contact: {
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginTop: 10,
  },
  halfFirst: {
    width: '49%',
    marginRight: 10,
  },
  halfSecond: {
    width: '49%',
  },
  text: {
    color: '#000000',
    fontFamily: 'Lato',
    fontSize: 8,
    fontWeight: 'normal',
    lineHeight: 1,
  },
  costText: {
    color: '#000000',
    fontFamily: 'Lato',
    fontSize: 8,
    fontWeight: 'normal',
    lineHeight: 1,
    marginBottom: 10,
    maxWidth: 180,
  },

  center: {
    textAlign: 'center',
  },
  header1: {
    color: '#233592',
    fontWeight: 'bold',
    fontSize: 16,
    marginBottom: 10,
  },
  header2: {
    color: '#233592',
    fontWeight: 'bold',
    fontSize: 10,
  },
  header3: {
    color: '#233592',
    fontWeight: 'bold',
    fontSize: 8,
    marginBottom: 5,
  },
  checklistHeader: {
    color: '#233592',
    fontWeight: 'bold',
    fontSize: 8,
  },
  link: {
    color: '#20abe2',
  },
  marginTop: {
    marginTop: 10,
  },
  smallFont: {
    fontSize: 7,
  },
  signature: {
    backgroundColor: '#ffffff',
    borderColor: '#dfe4ea',
    borderWidth: 1,
    borderRadius: 5,

    width: 210,
    height: 70,
  },
});

export const buildInvoicePdf = (
  jobData: Job,
  customer: Customer,
  contact: any,
  payment: any,
  loggedInCompany: Company,
) => {
  if (!jobData || !customer || !contact || !loggedInCompany) {
    return null;
  }

  let totalCosts = 0;

  jobData.costs.forEach((c) => {
    totalCosts = totalCosts + parseFloat((c.amount as unknown) as string);
  });

  const totalWithoutVAT = jobData.vat ? payment.amount / 1.2 : payment.amount;

  const logo = loggedInCompany.theme.find(
    (t) => t.type === CompanyThemeItemType.LOGO,
  );

  return (
    <Document
      title={`${jobData.jobPrefix ? `${jobData.jobPrefix}-` : ''}${
        jobData.jobId ? `${jobData.jobId} - ` : ''
      } ${payment.paymentNumber} Invoice`}
    >
      <Page size={'a4'} style={styles.page}>
        {logo && (
          <Image
            style={[styles.logo]}
            src={MediaHelper.getMediaUrl(logo.mediaId)}
          />
        )}
        <View style={styles.section}>
          <Text style={[styles.header1, styles.center]}>{`Invoice`}</Text>
        </View>

        <View style={[styles.section, styles.row]}>
          {/*Company Details*/}
          <View style={[styles.boxed, styles.halfFirst]}>
            <Text style={styles.header2}>Company Details</Text>
            <View style={{marginTop: 10}}>
              {loggedInCompany.name ? (
                <Text style={styles.text}>{loggedInCompany.name}</Text>
              ) : null}
              <Text style={styles.text}>
                {loggedInCompany.contact &&
                  loggedInCompany.contact.address.address1}
              </Text>
              {loggedInCompany.contact &&
              loggedInCompany.contact.address.address2 ? (
                <Text style={styles.text}>
                  {loggedInCompany.contact.address.address2}
                </Text>
              ) : null}
              {loggedInCompany.contact &&
              loggedInCompany.contact.address.address3 ? (
                <Text style={styles.text}>
                  {loggedInCompany.contact.address.address3}
                </Text>
              ) : null}
              <Text style={styles.text}>
                {loggedInCompany.contact &&
                  loggedInCompany.contact.address.postalCode}
              </Text>
              <Text style={styles.text}>
                {loggedInCompany.contact && loggedInCompany.contact.phone}
              </Text>
              <Link
                src={`mailto:${loggedInCompany.contact &&
                  loggedInCompany.contact.email}`}
                style={[styles.link, styles.marginTop]}
              >
                <Text style={styles.text}>
                  {loggedInCompany.contact && loggedInCompany.contact.email}
                </Text>
              </Link>
            </View>
          </View>

          {/*Customer Details*/}
          <View style={[styles.boxed, styles.halfSecond]}>
            <Text style={styles.header2}>Customer Details</Text>
            <View style={{marginTop: 10}}>
              {customer.companyName ? (
                <Text style={styles.text}>{customer.companyName}</Text>
              ) : null}
              {customer.account ? (
                <Text style={styles.text}>{customer.account}</Text>
              ) : null}

              <View style={styles.contact}>
                <View style={[styles.halfFirst]}>
                  <Text style={styles.header3}>Billing Address</Text>
                  <Text style={styles.text}>
                    {startCase(contact.billing.title)}{' '}
                    {contact.billing.firstName
                      ? contact.billing.firstName + ' '
                      : ''}
                    {contact.billing.lastName}
                  </Text>
                  <Text style={styles.text}>
                    {contact.billing.address.address1}
                  </Text>
                  {contact.billing.address.address2 ? (
                    <Text style={styles.text}>
                      {contact.billing.address.address2}
                    </Text>
                  ) : null}
                  {contact.billing.address.address3 ? (
                    <Text style={styles.text}>
                      {contact.billing.address.address3}
                    </Text>
                  ) : null}
                  <Text style={styles.text}>
                    {contact.billing.address.postalCode}
                  </Text>
                  <Text style={styles.text}>{contact.billing.phone}</Text>
                  {contact.billing.email ? (
                    <Link
                      src={`mailto:${contact.billing.email}`}
                      style={[styles.link, styles.marginTop]}
                    >
                      <Text style={styles.text}>{contact.billing.email}</Text>
                    </Link>
                  ) : null}
                </View>

                <View style={styles.halfSecond}>
                  <Text style={styles.header3}>Installation Address</Text>
                  <Text style={styles.text}>
                    {startCase(contact.installation.title)}{' '}
                    {contact.installation.firstName
                      ? contact.installation.firstName + ' '
                      : ''}
                    {contact.installation.lastName}
                  </Text>
                  <Text style={styles.text}>
                    {contact.installation.address.address1}
                  </Text>
                  {contact.installation.address.address2 ? (
                    <Text style={styles.text}>
                      {contact.installation.address.address2}
                    </Text>
                  ) : null}
                  {contact.installation.address.address3 ? (
                    <Text style={styles.text}>
                      {contact.installation.address.address3}
                    </Text>
                  ) : null}
                  <Text style={styles.text}>
                    {contact.installation.address.postalCode}
                  </Text>
                  <Text style={styles.text}>{contact.installation.phone}</Text>
                  {contact.installation.email ? (
                    <Link
                      src={`mailto:${contact.installation.email}`}
                      style={[styles.link, styles.marginTop]}
                    >
                      <Text style={styles.text}>
                        {contact.installation.email}
                      </Text>
                    </Link>
                  ) : null}
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={[styles.section, styles.boxed]}>
          <Text style={styles.header2}>Job Details</Text>

          <View style={[styles.section, styles.row]}>
            {jobData.jobId ? (
              <View style={styles.block}>
                <Text style={styles.header3}>Job ID</Text>
                <Text style={styles.costText}>{`${
                  jobData.jobPrefix ? `${jobData.jobPrefix}-` : ''
                }${jobData.jobId}`}</Text>
              </View>
            ) : null}

            {jobData.type ? (
              <View style={styles.block}>
                <Text style={styles.header3}>Job Type</Text>
                <Text style={styles.costText}>{jobData.type}</Text>
              </View>
            ) : null}

            {jobData.description ? (
              <View style={styles.block}>
                <View>
                  <Text style={styles.header3}>Job Description</Text>
                  <Text style={styles.costText}>{jobData.description}</Text>
                </View>
              </View>
            ) : null}
          </View>
        </View>

        <View style={[styles.section, styles.boxed]}>
          {/*Headers*/}
          <View style={[styles.section]}>
            <Text style={styles.header2}>Invoice Details</Text>

            <View style={[styles.section, styles.row, styles.costRow]}>
              {payment.invoiceNumber ? (
                <View style={styles.costItem}>
                  <Text style={[styles.header3, {marginBottom: 10}]}>
                    Invoice No.
                  </Text>
                  <Text style={styles.costText}>{payment.invoiceNumber}</Text>
                </View>
              ) : null}

              <View style={styles.costItem}>
                <Text style={[styles.header3, {marginBottom: 10}]}>
                  Invoice Ref
                </Text>
                <Text style={styles.costText}>{payment.paymentNumber}</Text>
              </View>

              <View style={styles.costItem}>
                <Text style={[styles.header3, {marginBottom: 10}]}>
                  Description
                </Text>
                <Text style={styles.costText}>{payment.description}</Text>
              </View>

              <View style={styles.costItem}>
                <Text style={[styles.header3, {marginBottom: 10}]}>
                  Net Amount
                </Text>
                <Text style={styles.costText}>
                  £
                  {parseFloat((totalWithoutVAT as unknown) as string).toFixed(
                    2,
                  )}
                </Text>
              </View>

              {jobData.vat ? (
                <View style={styles.costItem}>
                  <Text style={[styles.header3, {marginBottom: 10}]}>VAT</Text>
                  <Text style={styles.costText}>
                    £
                    {parseFloat(
                      ((payment.amount - totalWithoutVAT) as unknown) as string,
                    ).toFixed(2)}
                  </Text>
                </View>
              ) : null}

              <View style={styles.costItem}>
                <Text style={[styles.header3, {marginBottom: 10}]}>Total</Text>
                <Text style={styles.costText}>
                  £
                  {parseFloat((payment.amount as unknown) as string).toFixed(2)}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const InvoicePdfBuilder: React.FunctionComponent<InvoicePdfBuilderProps> = (
  props: InvoicePdfBuilderProps,
) => {
  const [loadingPdf, setLoadingPdf] = useState(true);

  if (!props.data) {
    return null;
  }

  const uploadAttachment = async (blob) => {
    const result = await props.action(
      CRUDType.UPLOAD_MEDIA,
      DataType.ATTACHMENT,
      {
        body: {
          created: new Date(),
          createdBy: props.loggedInUser,
          title: createFilename(
            props.data._id,
            props.data.customer,
            props.loggedInCompany,
          ),
        },
        blob,
      },
      false,
    );

    props.onChange(result.data._id);
  };

  return (
    <div className={'PdfBuilderContainer'}>
      <Button
        disabled={
          !props.customer ||
          !props.contact?.billing ||
          !props.contact?.installation
        }
        title={'invoice'}
        onClick={async () => {
          setLoadingPdf(false);
        }}
      >
        View Invoice
      </Button>

      {!loadingPdf ? (
        <BlobProvider
          document={buildInvoicePdf(
            props.data,
            props.customer,
            props.contact,
            props.payment,
            props.loggedInCompany,
          )}
        >
          {({blob}) => {
            if (blob) {
              uploadAttachment(blob);
              setLoadingPdf(true);
            }
            return null;
          }}
        </BlobProvider>
      ) : null}
    </div>
  );
};

export default InvoicePdfBuilder;
