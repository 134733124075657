import {
  BlobProvider,
  Document,
  Font,
  Image,
  Link,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import {cloneDeep, startCase} from 'lodash';
import moment from 'moment';
import React, {useState} from 'react';
import {Button} from 'semantic-ui-react';
import {DataType} from '../const/crud';
import {Company, CompanyThemeItemType} from '../models/Company';
import {Customer} from '../models/Customer';
import {Job, JobStage, jobStageMap} from '../models/Job';
import {User} from '../models/User';
import MediaHelper from '../utils/mediaHelper';
import {CRUDType} from './Data/DataConst';

interface CostPdfBuilderProps {
  buttonTitle: string;
  buttonTitleView: string;
  data: any;
  loggedInUser: string;
  loggedInCompany: Company;
  action: any;
  customer: Customer;
  contact: any;
  users: User[];
  company: Company;
  onChange: any;
}

const latoReg = require('../assets/Lato-Regular.ttf').default;
const latoBold = require('../assets/Lato-Bold.ttf').default;

Font.register({
  family: 'Lato',
  src: '',
  fonts: [
    {
      src: latoReg,
      fontWeight: 'normal',
    },
    {
      src: latoBold,
      fontWeight: 'bold',
    },
  ],
});

const createFilename = (stage: JobStage, customerId: string, stageTitles) =>
  `${jobStageMap(
    stage,
    stageTitles,
  ).toLowerCase()}-completion-report-${customerId}-${moment().format(
    'DD-MM-YYYY-HH-mm-ss',
  )}.pdf`;

const styles = StyleSheet.create({
  logo: {
    backgroundColor: '#ffffff',
    marginVertical: 15,
    marginHorizontal: 150,
  },
  page: {
    flexDirection: 'column',
    paddingVertical: 20,
    paddingHorizontal: 40,
  },
  section: {
    flexDirection: 'column',
    marginVertical: 5,
  },
  fullWidth: {
    justifyContent: 'space-between',
  },
  row: {
    flexDirection: 'row',
  },
  costRow: {
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  costItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  boxed: {
    padding: 10,
    borderColor: '#dfe4ea',
    borderWidth: 1,
    borderRadius: 5,
  },
  block: {
    marginRight: 20,
  },
  quarter: {
    width: '24.25%',
    marginRight: 10,
  },
  quarterLast: {
    width: '24.25%',
  },
  contact: {
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginTop: 10,
  },
  halfFirst: {
    width: '49%',
    marginRight: 10,
  },
  halfSecond: {
    width: '49%',
  },
  text: {
    color: '#000000',
    fontFamily: 'Lato',
    fontSize: 8,
    fontWeight: 'normal',
    lineHeight: 1,
  },
  costText: {
    color: '#000000',
    fontFamily: 'Lato',
    fontSize: 8,
    fontWeight: 'normal',
    lineHeight: 1,
    marginBottom: 10,
  },

  center: {
    textAlign: 'center',
  },
  header1: {
    color: '#233592',
    fontWeight: 'bold',
    fontSize: 16,
    marginBottom: 10,
  },
  header2: {
    color: '#233592',
    fontWeight: 'bold',
    fontSize: 10,
  },
  header3: {
    color: '#233592',
    fontWeight: 'bold',
    fontSize: 8,
    marginBottom: 5,
  },
  checklistHeader: {
    color: '#233592',
    fontWeight: 'bold',
    fontSize: 8,
  },
  link: {
    color: '#20abe2',
  },
  marginTop: {
    marginTop: 10,
  },
  smallFont: {
    fontSize: 7,
  },
  signature: {
    backgroundColor: '#ffffff',
    borderColor: '#dfe4ea',
    borderWidth: 1,
    borderRadius: 5,

    width: 210,
    height: 70,
  },
});

const buildPdf = (
  jobData: Job,
  customer: Customer,
  contact: any,
  users: User[],
  company: Company,
  loggedInCompany: Company,
) => {
  if (
    !jobData ||
    !customer ||
    !contact ||
    !users?.length ||
    !company ||
    !loggedInCompany
  ) {
    return null;
  }

  let totalCosts = 0;

  jobData.costs.forEach((c) => {
    totalCosts = totalCosts + parseFloat((c.amount as unknown) as string);
  });

  const totalProfit =
    (jobData.vat ? jobData.price.value / 1.2 : jobData.price.value) -
    totalCosts;

  const logo = company.theme.find((t) => t.type === CompanyThemeItemType.LOGO);

  return (
    <Document
      title={`${jobData.jobPrefix ? `${jobData.jobPrefix}-` : ''}${
        jobData.jobId ? `${jobData.jobId} - ` : ''
      } Cost Report`}
    >
      <Page size={'a4'} style={styles.page}>
        {logo && (
          <Image
            style={[styles.logo]}
            src={MediaHelper.getMediaUrl(logo.mediaId)}
          />
        )}
        <View style={styles.section}>
          <Text style={[styles.header1, styles.center]}>{`Cost Report`}</Text>
        </View>

        <View style={[styles.section, styles.row]}>
          {/*Company Details*/}
          <View style={[styles.boxed, styles.halfFirst]}>
            <Text style={styles.header2}>Company Details</Text>
            <View style={{marginTop: 10}}>
              {company.name ? (
                <Text style={styles.text}>{company.name}</Text>
              ) : null}
              <Text style={styles.text}>
                {company.contact && company.contact.address.address1}
              </Text>
              {company.contact && company.contact.address.address2 ? (
                <Text style={styles.text}>
                  {company.contact.address.address2}
                </Text>
              ) : null}
              {company.contact && company.contact.address.address3 ? (
                <Text style={styles.text}>
                  {company.contact.address.address3}
                </Text>
              ) : null}
              <Text style={styles.text}>
                {company.contact && company.contact.address.postalCode}
              </Text>
              <Text style={styles.text}>
                {company.contact && company.contact.phone}
              </Text>
              <Link
                src={`mailto:${company.contact && company.contact.email}`}
                style={[styles.link, styles.marginTop]}
              >
                <Text style={styles.text}>
                  {company.contact && company.contact.email}
                </Text>
              </Link>
            </View>
          </View>

          {/*Customer Details*/}
          <View style={[styles.boxed, styles.halfSecond]}>
            <Text style={styles.header2}>Customer Details</Text>
            <View style={{marginTop: 10}}>
              {customer.companyName ? (
                <Text style={styles.text}>{customer.companyName}</Text>
              ) : null}
              {customer.account ? (
                <Text style={styles.text}>{customer.account}</Text>
              ) : null}

              <View style={styles.contact}>
                <View style={[styles.halfFirst]}>
                  <Text style={styles.header3}>Billing Address</Text>
                  <Text style={styles.text}>
                    {startCase(contact.billing.title)}{' '}
                    {contact.billing.firstName
                      ? contact.billing.firstName + ' '
                      : ''}
                    {contact.billing.lastName}
                  </Text>
                  <Text style={styles.text}>
                    {contact.billing.address.address1}
                  </Text>
                  {contact.billing.address.address2 ? (
                    <Text style={styles.text}>
                      {contact.billing.address.address2}
                    </Text>
                  ) : null}
                  {contact.billing.address.address3 ? (
                    <Text style={styles.text}>
                      {contact.billing.address.address3}
                    </Text>
                  ) : null}
                  <Text style={styles.text}>
                    {contact.billing.address.postalCode}
                  </Text>
                  <Text style={styles.text}>{contact.billing.phone}</Text>
                  {contact.billing.email ? (
                    <Link
                      src={`mailto:${contact.billing.email}`}
                      style={[styles.link, styles.marginTop]}
                    >
                      <Text style={styles.text}>{contact.billing.email}</Text>
                    </Link>
                  ) : null}
                </View>

                <View style={styles.halfSecond}>
                  <Text style={styles.header3}>Installation Address</Text>
                  <Text style={styles.text}>
                    {startCase(contact.installation.title)}{' '}
                    {contact.installation.firstName
                      ? contact.installation.firstName + ' '
                      : ''}
                    {contact.installation.lastName}
                  </Text>
                  <Text style={styles.text}>
                    {contact.installation.address.address1}
                  </Text>
                  {contact.installation.address.address2 ? (
                    <Text style={styles.text}>
                      {contact.installation.address.address2}
                    </Text>
                  ) : null}
                  {contact.installation.address.address3 ? (
                    <Text style={styles.text}>
                      {contact.installation.address.address3}
                    </Text>
                  ) : null}
                  <Text style={styles.text}>
                    {contact.installation.address.postalCode}
                  </Text>
                  <Text style={styles.text}>{contact.installation.phone}</Text>
                  {contact.installation.email ? (
                    <Link
                      src={`mailto:${contact.installation.email}`}
                      style={[styles.link, styles.marginTop]}
                    >
                      <Text style={styles.text}>
                        {contact.installation.email}
                      </Text>
                    </Link>
                  ) : null}
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={[styles.section, styles.boxed]}>
          <Text style={styles.header2}>Job Details</Text>

          <View style={[styles.section, styles.row]}>
            {jobData.jobId ? (
              <View style={styles.block}>
                <Text style={styles.header3}>Job ID</Text>
                <Text style={styles.text}>{`${
                  jobData.jobPrefix ? `${jobData.jobPrefix}-` : ''
                }${jobData.jobId}`}</Text>
              </View>
            ) : null}

            {jobData.type ? (
              <View style={styles.block}>
                <Text style={styles.header3}>Job Type</Text>
                <Text style={styles.text}>{jobData.type}</Text>
              </View>
            ) : null}

            {jobData.description ? (
              <View style={[styles.section, styles.row]}>
                <View>
                  <Text style={styles.header3}>Job Description</Text>
                  <Text style={styles.text}>{jobData.description}</Text>
                </View>
              </View>
            ) : null}
          </View>

          <Text style={styles.header2}>Job Value</Text>

          <View style={[styles.section, styles.row]}>
            <View style={styles.block}>
              <Text style={styles.header3}>Net Amount</Text>
              <Text style={styles.text}>
                £
                {(jobData.vat
                  ? jobData.price.value / 1.2
                  : jobData.price.value
                ).toFixed(2)}
              </Text>
            </View>

            <View style={styles.block}>
              <Text style={styles.header3}>VAT Amount</Text>
              <Text style={styles.text}>
                £
                {(jobData.vat
                  ? jobData.price.value - (jobData.price.value || 0) / 1.2
                  : 0
                ).toFixed(2)}
              </Text>
            </View>

            <View style={styles.block}>
              <Text style={styles.header3}>Total Amount</Text>
              <Text style={styles.text}> £{jobData.price.value}</Text>
            </View>
          </View>

          {/*Headers*/}
          <View style={[styles.section]}>
            <Text style={styles.header2}>Costs</Text>

            <View style={[styles.section, styles.row, styles.costRow]}>
              <View style={styles.costItem}>
                <Text style={[styles.header3, {marginBottom: 10}]}>Name</Text>
                {jobData.costs.map((c, i) => {
                  return (
                    <Text key={`text-cost-${c}-${i}`} style={styles.costText}>
                      {c.name}
                    </Text>
                  );
                })}
              </View>

              <View style={styles.costItem}>
                <Text style={[styles.header3, {marginBottom: 10}]}>
                  Net Amount
                </Text>
                {jobData.costs.map((c, i) => {
                  let value = parseFloat((c.amount as unknown) as string);

                  return (
                    <Text key={`cost-item-net-${i}`} style={styles.costText}>
                      £{value.toFixed(2)}
                    </Text>
                  );
                })}
              </View>

              <View style={styles.costItem}>
                <Text style={[styles.header3, {marginBottom: 10}]}>VAT</Text>
                {jobData.costs.map((c, i) => {
                  let value = parseFloat((c.amount as unknown) as string);

                  if (jobData.vat) {
                    value = value * 0.2;
                  }

                  return (
                    <Text key={`cost-item-vat-${i}`} style={styles.costText}>
                      £{value.toFixed(2)}
                    </Text>
                  );
                })}
              </View>

              <View style={styles.costItem}>
                <Text style={[styles.header3, {marginBottom: 10}]}>
                  Total Amount
                </Text>
                {jobData.costs.map((c, i) => {
                  let value = parseFloat((c.amount as unknown) as string);

                  if (jobData.vat) {
                    value = value + value * 0.2;
                  }

                  return (
                    <Text key={`cost-item-total-${i}`} style={styles.costText}>
                      £{value.toFixed(2)}
                    </Text>
                  );
                })}
              </View>

              <View style={styles.costItem}>
                <Text style={[styles.header3, {marginBottom: 10}]}>Stage</Text>
                {jobData.costs.map((c, i) => {
                  return (
                    <Text key={`cost-item-stage-${i}`} style={styles.costText}>
                      {jobStageMap(c.stage, loggedInCompany.stageTitles)}
                    </Text>
                  );
                })}
              </View>

              <View style={styles.costItem}>
                <Text style={[styles.header3, {marginBottom: 10}]}>
                  Description
                </Text>
                {jobData.costs.map((c, i) => {
                  return (
                    <Text key={`cost-item-desc-${i}`} style={styles.costText}>
                      {c.description}
                    </Text>
                  );
                })}
              </View>

              <View style={styles.costItem}>
                <Text style={[styles.header3, {marginBottom: 10}]}>
                  Supplier
                </Text>
                {jobData.costs.map((c, i) => {
                  return (
                    <Text key={`cost-item-supp-${i}`} style={styles.costText}>
                      {c.company}
                    </Text>
                  );
                })}
              </View>

              <View style={styles.costItem}>
                <Text style={[styles.header3, {marginBottom: 10}]}>
                  Invoice
                </Text>
                {jobData.costs.map((c, i) => {
                  return (
                    <Text
                      key={`cost-item-invoice-${i}`}
                      style={styles.costText}
                    >
                      {c.invoiceNumber}
                    </Text>
                  );
                })}
              </View>

              <View style={styles.costItem}>
                <Text style={[styles.header3, {marginBottom: 10}]}>%</Text>
                {jobData.costs.map((c, i) => {
                  let per = (
                    (parseFloat((c.amount as unknown) as string) /
                      (jobData.vat
                        ? jobData.price.value / 1.2
                        : jobData.price.value)) *
                    100
                  ).toFixed(2) || 0;

                  if (per === 'Infinity') {
                    per = 0;
                  }

                  return (
                    <Text key={`cost-item-per-${i}`} style={styles.costText}>
                      {per}
                    </Text>
                  );
                })}
              </View>
            </View>
          </View>

          <View style={[styles.section]}>
            <Text style={styles.header2}>Total Net Costs</Text>
            <Text style={styles.text}>£{totalCosts.toFixed(2)}</Text>
          </View>

          <View style={[styles.section]}>
            <Text style={styles.header2}>Total Gross Profit</Text>
            <Text style={styles.text}>
              £{totalProfit.toFixed(2)} (
              {totalProfit > 0
                ? (
                    (totalProfit /
                      (jobData.vat
                        ? jobData.price.value / 1.2
                        : jobData.price.value)) *
                    100
                  ).toFixed(2)
                : '0'}
              %)
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const CostPdfBuilder: React.FunctionComponent<CostPdfBuilderProps> = (
  props: CostPdfBuilderProps,
) => {
  const [loadingPdf, setLoadingPdf] = useState(true);

  if (!props.data) {
    return null;
  }

  let totalCosts = 0;

  props.data.costs.forEach((c) => {
    totalCosts = totalCosts + parseFloat((c.amount as unknown) as string);
  });

  let valueLessVat = props.data.price.value || 0;

  if (props.data.price.value && props.data.vat) {
    valueLessVat = props.data.price.value / 1.2;
  }

  const totalProfit = valueLessVat - totalCosts;
  const totalPer =
    totalProfit && valueLessVat && valueLessVat > 0
      ? ((totalProfit / valueLessVat) * 100).toFixed(2)
      : 0;

  const uploadAttachment = async (blob) => {
    const result = await props.action(
      CRUDType.UPLOAD_MEDIA,
      DataType.ATTACHMENT,
      {
        body: {
          created: new Date(),
          createdBy: props.loggedInUser,
          title: createFilename(
            props.data._id,
            props.data.customer,
            props.loggedInCompany,
          ),
        },
        blob,
      },
      false,
    );

    const clonedData = cloneDeep(props.data);

    // Mark as report generated
    clonedData.costReport = result.data._id;

    props.onChange(clonedData);
  };

  return (
    <div className={'PdfBuilderContainer'}>
      <div style={{marginBottom: 20}}>
        <h2>
          Total Gross Profit:{' '}
          <p style={{color: 'black'}}>
            £
            {props.data.vat
              ? `${totalProfit ? totalProfit.toFixed(2) : 0} `
              : (totalProfit || 0).toFixed(2)}
            ({totalPer}%)
          </p>
        </h2>
      </div>

      {props.data.costReport ? (
        <Button
          primary
          as={'a'}
          href={MediaHelper.getMediaUrl(props.data.costReport)}
          target={'_blank'}
        >
          View Cost Report
        </Button>
      ) : null}

      {props.data.costs.length > 0 &&
      props.data &&
      props.customer &&
      props.contact &&
      props.users?.length &&
      props.company &&
      props.loggedInCompany ? (
        <Button
          loading={!loadingPdf && !props.data.costReport}
          title={'cost'}
          secondary={!!(props.data.costReport && props.data.costReport.length)}
          onClick={async () => {
            setLoadingPdf(false);
          }}
        >
          Generate Cost Report
        </Button>
      ) : null}

      {!loadingPdf ? (
        <BlobProvider
          document={buildPdf(
            props.data,
            props.customer,
            props.contact,
            props.users,
            props.company,
            props.loggedInCompany,
          )}
        >
          {({blob}) => {
            if (blob) {
              uploadAttachment(blob);
              setLoadingPdf(true);
            }
            return null;
          }}
        </BlobProvider>
      ) : null}
    </div>
  );
};

export default CostPdfBuilder;
