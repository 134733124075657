import {startCase} from 'lodash';
import {DropdownOption} from '../components/Data/DataConst';
import {JobStage} from './Job';
import {MediaType} from './Media';

export enum CustomFieldType {
  TEXT = 'text',
  SELECT = 'select',
  NUMBER = 'number',
  CURRENCY = 'currency',
  DATE = 'date',
}

export interface Company {
  _id: string;
  __v: number;
  created: Date;
  createdBy: string;
  name: string;
  industry: string;
  status: string;
  subscription: {
    id: string;
    expiry: null | Date;
    userCount: number;
  };
  contact: {
    phone: string;
    address: {
      address1: string;
      address2?: string;
      address3?: string;
      postalCode: string;
    };
    email: string;
  };
  contacts: {
    primary: string;
    secondary: string;
  };
  stageTitles: {
    [JobStage.PRE]: string;
    [JobStage.POS]: string;
    [JobStage.ADMIN]: string;
    [JobStage.POST]: string;
    [JobStage.SERVICE]: string;
  };
  theme: {
    _id: string;
    created: Date;
    createdBy: string;
    type: string;
    mediaType: MediaType;
    mediaId: string;
  }[];
  checklists: {
    _id: string;
    created: Date;
    createdBy: string;
    name: string;
    checklistType: string;
    count: number;
    tasks: {
      _id: string;
      created: Date;
      createdBy: string;
      completed: Date;
      completedBy: string;
      text: string;
      status: string;
    }[];
  }[];
  costGroups: {
    _id: string;
    created: Date;
    createdBy: string;
    name: string;
    costs: {
      _id: string;
      created: Date;
      createdBy: string;
      name: string;
      stage: JobStage;
      type: string;
      description: string;
      invoiceNumber: string;
      company: string;
      amount: number;
    }[];
  }[];
  jobCodes: {
    _id: string;
    created: Date;
    createdBy: string;
    name: string;
    startNumber: string;
  }[];
  customFields: {
    _id: string;
    created: Date;
    createdBy: string;
    name: string;
    type: CustomFieldType;
    isRequired: boolean;
    selectOptions?: string[];
  }[];
  staffAvailability: {
    _id: string;
    created: Date;
    createdBy: string;
    userId: string;
    startDate: Date;
    endDate: Date;
    description: string;
  }[];
  leadSources: {
    _id: string;
    created: Date;
    createdBy: string;
    name: string;
  }[];
  integrations: {
    xero?: {
      id_token?: string;
      access_token?: string;
      expires_at: number;
      refresh_token: string;
    };
    quickbooks?: {
      realmId?: string;
      id_token?: string;
      access_token?: string;
      expires_at: number;
      refresh_token: string;
    };
  };
  keap: {
    id?: string;
    name?: string;
    apiKey?: string;
  };
}

export const companyInitialState: Company = {
  _id: '',
  __v: 0,
  created: new Date(),
  createdBy: '',
  name: '',
  industry: '',
  status: '',
  subscription: {
    id: '',
    expiry: null,
    userCount: 0,
  },
  contact: {
    phone: '',
    address: {
      address1: '',
      address2: '',
      address3: '',
      postalCode: '',
    },
    email: '',
  },
  contacts: {
    primary: '',
    secondary: '',
  },
  stageTitles: {
    [JobStage.POS]: JobStage.POST,
    [JobStage.PRE]: JobStage.PRE,
    [JobStage.ADMIN]: JobStage.ADMIN,
    [JobStage.POST]: JobStage.POST,
    [JobStage.SERVICE]: JobStage.SERVICE,
  },
  theme: [],
  checklists: [],
  costGroups: [],
  jobCodes: [],
  customFields: [],
  staffAvailability: [],
  leadSources: [],
  integrations: {},
  keap: {},
};

export enum CompanyStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum CompanyThemeItemType {
  LOGO = 'logo',
}

export class CompanyEnums {
  public static readonly CompanyStatuses = [
    CompanyStatus.ACTIVE,
    CompanyStatus.INACTIVE,
  ];

  public static readonly CompanyStatusOptions = [
    {
      text: startCase(CompanyStatus.ACTIVE),
      value: CompanyStatus.ACTIVE,
    },
    {
      text: startCase(CompanyStatus.INACTIVE),
      value: CompanyStatus.INACTIVE,
    },
  ];

  public static readonly CompanyThemeOptions = [
    {
      text: startCase(CompanyThemeItemType.LOGO),
      value: CompanyThemeItemType.LOGO,
    },
  ];

  public static readonly CompanyCustomFieldTypeOptions = [
    {
      text: startCase(CustomFieldType.TEXT),
      value: CustomFieldType.TEXT,
    },
    {
      text: startCase(CustomFieldType.SELECT),
      value: CustomFieldType.SELECT,
    },
    // {
    //   text: startCase(CustomFieldType.NUMBER),
    //   value: CustomFieldType.NUMBER,
    // },
    // {
    //   text: startCase(CustomFieldType.CURRENCY),
    //   value: CustomFieldType.CURRENCY,
    // },
    // {
    //   text: startCase(CustomFieldType.DATE),
    //   value: CustomFieldType.DATE,
    // },
  ];

  public static readonly CompanyCustomFieldRequiredOptions: DropdownOption[] = [
    {
      key: 'custom-field-required-true',
      text: 'Yes, field is required',
      value: 'true',
    },
    {
      key: 'custom-field-required-false',
      text: 'No, field is optional',
      value: 'false',
    },
  ];
}
