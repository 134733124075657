import {
  faBuilding,
  faClipboard,
  faClock,
  faCogs,
  faFileInvoice,
  faKeyboard,
  faLocationArrow,
  faPaintBrush,
  faPencilAlt,
  faUsersCog,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {DataType} from '../../const/crud';
import CustomModalProps from '../../const/modal';
import {
  CompanyEnums,
  companyInitialState,
  CompanyThemeItemType,
} from '../../models/Company';
import {UserRole} from '../../models/User';
import ActionHelper from '../../utils/actionHelper';
import {DataHelper, ValueTemplate} from '../../utils/dataHelper';
import MediaHelper from '../../utils/mediaHelper';
import {
  CRUDType,
  DataActionType,
  DataFormType,
  DataTableType,
  DropdownOption,
  SortDirection,
  ValidationType,
} from '../Data/DataConst';
import DataModal from '../Data/DataModal';
import DataTable from '../Data/DataTable';
import QuickbooksAuth from '../Integration/quickbooks/QuickbooksAuth';
import XeroAuth from '../Integration/xero/XeroAuth';

interface CompanyModalProps extends CustomModalProps {
  actionHelper: ActionHelper;
  industries: DropdownOption[];
}

const CompanyModal: React.FunctionComponent<CompanyModalProps> = (
  props: CompanyModalProps,
) => {
  const resetDataForm = () => {
    // Reset search options
    props.setSearchOptions({});

    props.setSelected(companyInitialState);
    props.setDataForm(false);
  };

  const handleDataAction = async (action: DataActionType, dataId: string) => {
    await props.actionHelper.handleDataAction(
      DataType.COMPANY,
      (state) => props.setSelected(state),
      action,
      props.selected._id,
      dataId,
      [
        DataActionType.EDIT_CHECKLIST_TEMPLATE,
        DataActionType.EDIT_COST_GROUP,
        DataActionType.EDIT_JOB_CODE,
        DataActionType.EDIT_LEAD_SOURCE,
        DataActionType.EDIT_CUSTOM_FIELD,
      ].includes(action)
        ? props.selected
        : action === DataActionType.CREATE_THEME
        ? !!props.selected.theme.find(
            (item) => item.type === CompanyThemeItemType.LOGO,
          )
        : action === DataActionType.CREATE_STAFF_AVAILABILITY
        ? {
            searchOptions: props.searchOptions,
            userOptions: props.userOptions,
            handleSearch: props.searchAction,
            test: 'mew',
          }
        : action === DataActionType.EDIT_STAFF_AVAILABILITY
        ? {
            selected: props.selected,
            searchOptions: props.searchOptions,
            userOptions: props.userOptions,
            handleSearch: props.searchAction,
            test: 'mew',
          }
        : null,
    );
  };

  const companySection = {
    title: 'Company Details',
    titleIcon: <FontAwesomeIcon icon={faBuilding} />,
    fields: [
      {
        title: 'Name',
        dataField: 'name',
        type: DataFormType.TEXT,
        validation: [ValidationType.REQUIRED],
      },
      {
        className: 'halfWidth',
        title: 'Phone',
        dataField: 'contact.phone',
        type: DataFormType.TEXT,
        validation: [ValidationType.REQUIRED],
      },
      {
        className: 'halfWidth',
        title: 'Email',
        dataField: 'contact.email',
        type: DataFormType.TEXT_LOWER,
        validation: [ValidationType.REQUIRED],
      },
      {
        className: 'halfWidth',
        title: 'Address 1',
        dataField: 'contact.address.address1',
        type: DataFormType.TEXT,
        validation: [ValidationType.REQUIRED],
      },
      {
        className: 'halfWidth',
        title: 'Address 2',
        dataField: 'contact.address.address2',
        type: DataFormType.TEXT,
        optional: true,
      },
      {
        className: 'halfWidth',
        title: 'Town/City',
        dataField: 'contact.address.address3',
        type: DataFormType.TEXT,
        validation: [ValidationType.REQUIRED],
      },
      {
        className: 'halfWidth',
        title: 'Postcode',
        dataField: 'contact.address.postalCode',
        type: DataFormType.TEXT_UPPER,
        validation: [ValidationType.REQUIRED],
      },
    ],
  };

  const contactSection = {
    title: 'Contacts',
    titleIcon: <FontAwesomeIcon icon={faUsersCog} />,
    fields: [
      {
        title: 'Primary Contact',
        dataField: 'contacts.primary',
        dataSet:
          props.searchOptions[DataType.USER] &&
          props.searchOptions[DataType.USER].length
            ? props.searchOptions[DataType.USER]
            : props.userOptions,
        dataType: DataType.USER,
        type: DataFormType.SEARCH_SELECT_API,
      },
      {
        title: 'Secondary Contact',
        dataField: 'contacts.secondary',
        dataSet:
          props.searchOptions[DataType.USER] &&
          props.searchOptions[DataType.USER].length
            ? props.searchOptions[DataType.USER]
            : props.userOptions,
        dataType: DataType.USER,
        type: DataFormType.SEARCH_SELECT_API,
        optional: true,
      },
    ],
  };

  const subscriptionSection = {
    title: 'Subscription',
    titleIcon: <FontAwesomeIcon icon={faFileInvoice} />,
    fields: [
      {
        className: 'halfWidth',
        title: 'Status',
        dataField: 'status',
        dataSet: CompanyEnums.CompanyStatusOptions,
        type: DataFormType.SELECT,
        validation: [ValidationType.REQUIRED],
      },
      {
        className: 'halfWidth',
        title: 'Subscription ID',
        dataField: 'subscription.id',
        type: DataFormType.TEXT,
        optional: true,
      },

      {
        className: 'halfWidth',
        title: 'Expiry',
        dataField: 'subscription.expiry',
        type: DataFormType.DATE,
        optional: true,
      },
      {
        className: 'halfWidth',
        title: 'Max Users',
        dataField: 'subscription.userCount',
        type: DataFormType.NUMBER,
        optional: true,
      },
    ],
  };

  const stageTitleSection = {
    title: 'Stage Titles',
    titleIcon: <FontAwesomeIcon icon={faPencilAlt} />,
    fields: [
      {
        className: 'halfWidth',
        title: 'POS/Sales Stage Title',
        dataField: 'stageTitles.pos',
        type: DataFormType.TEXT_CAPITALISE,
        optional: true,
        characterLimit: 20,
      },
      {
        className: 'halfWidth',
        title: 'PRE/Survey Stage Title',
        dataField: 'stageTitles.pre',
        type: DataFormType.TEXT_CAPITALISE,
        optional: true,
        characterLimit: 20,
      },
      {
        className: 'halfWidth',
        title: 'Admin Stage Title',
        dataField: 'stageTitles.admin',
        type: DataFormType.TEXT_CAPITALISE,
        optional: true,
        characterLimit: 20,
      },
      {
        className: 'halfWidth',
        title: 'POST/Installation Stage Title',
        dataField: 'stageTitles.post',
        type: DataFormType.TEXT_CAPITALISE,
        optional: true,
        characterLimit: 20,
      },
      {
        className: 'halfWidth',
        title: 'SERVICE Stage Title',
        dataField: 'stageTitles.service',
        type: DataFormType.TEXT_CAPITALISE,
        optional: true,
        characterLimit: 20,
      },
    ],
  };

  const themeSection = {
    fields: [
      {
        dataField: 'theme',
        type: DataFormType.TABLE,
        content: (
          <DataTable
            title="Theme"
            titleIcon={<FontAwesomeIcon icon={faPaintBrush} />}
            description={'Add any custom theme options like logos etc.'}
            data={props.selected.theme}
            dataType={DataType.THEME}
            primaryAction={DataActionType.CREATE_THEME}
            handleAction={handleDataAction}
            pageSize={5}
            sortField={['created']}
            sortDirection={[SortDirection.DESC]}
            actions={[
              {
                title: 'Delete',
                icon: 'delete',
                className: 'redButton',
                action: DataActionType.DELETE_THEME,
              },
            ]}
            cols={[
              {
                title: 'Media',
                dataField: 'mediaId',
                dataProcess: {
                  action: MediaHelper.getMediaUrl,
                  options: [],
                },
                type: DataTableType.MEDIA,
                mobileDisplay: true,
                width: 4,
              },
              {
                title: 'Type',
                dataField: 'type',
                type: DataTableType.PLAIN,
                mobileDisplay: true,
                width: 9,
              },
            ]}
          />
        ),
      },
    ],
  };

  const checklistSection = {
    fields: [
      {
        dataField: 'checklists',
        type: DataFormType.TABLE,
        content: (
          <DataTable
            title="Checklists"
            titleIcon={<FontAwesomeIcon icon={faClipboard} />}
            description={
              'Add any checklist templates to quickly add tasks to jobs here.'
            }
            data={props.selected.checklists}
            dataType={DataType.CHECKLIST_TEMPLATE}
            primaryAction={DataActionType.CREATE_CHECKLIST_TEMPLATE}
            handleAction={handleDataAction}
            pageSize={5}
            sortField={['created']}
            sortDirection={[SortDirection.DESC]}
            actions={[
              {
                title: 'Edit',
                icon: 'pencil',
                className: 'spaceBelow',
                action: DataActionType.EDIT_CHECKLIST_TEMPLATE,
              },
              {
                title: 'Delete',
                icon: 'delete',
                className: 'redButton',
                action: DataActionType.DELETE_CHECKLIST_TEMPLATE,
              },
            ]}
            cols={[
              {
                title: 'Checklist Name',
                dataField: 'name',
                type: DataTableType.PLAIN,
                mobileDisplay: true,
                width: 9,
              },
              {
                title: 'Type',
                dataField: 'checklistType',
                type: DataTableType.PLAIN,
                mobileDisplay: true,
                width: 3,
              },
              {
                title: 'Task Count',
                dataField: 'count',
                type: DataTableType.PLAIN,
                mobileDisplay: true,
                width: 3,
              },
              {
                title: 'Created Date',
                dataField: 'created',
                type: DataTableType.DATE_TIME,
                mobileDisplay: true,
                width: 3,
              },
              {
                title: 'Created By',
                dataField: 'createdBy',
                dataProcess: {
                  action: DataHelper.getValueFromData,
                  data: props.users,
                  field: ValueTemplate.NAME,
                },
                type: DataTableType.STRING,
                mobileDisplay: false,
                width: 3,
              },
            ]}
          />
        ),
      },
    ],
  };

  const costSection = {
    fields: [
      {
        dataField: 'costGroups',
        type: DataFormType.TABLE,
        content: (
          <DataTable
            title="Job Cost Groups"
            titleIcon={<FontAwesomeIcon icon={faClipboard} />}
            description={
              'Add any job cost group templates to quickly add costs to jobs here.'
            }
            data={props.selected.costGroups}
            dataType={DataType.COST_GROUPS}
            primaryAction={DataActionType.CREATE_COST_GROUP}
            handleAction={handleDataAction}
            pageSize={5}
            sortField={['created']}
            sortDirection={[SortDirection.DESC]}
            actions={[
              {
                title: 'Edit',
                icon: 'pencil',
                className: 'spaceBelow',
                action: DataActionType.EDIT_COST_GROUP,
              },
              {
                title: 'Delete',
                icon: 'delete',
                className: 'redButton',
                action: DataActionType.DELETE_COST_GROUP,
              },
            ]}
            cols={[
              {
                title: 'Cost Group Name',
                dataField: 'name',
                type: DataTableType.PLAIN,
                mobileDisplay: true,
                width: 9,
              },
              {
                title: 'Cost Count',
                dataField: 'count',
                type: DataTableType.PLAIN,
                mobileDisplay: true,
                width: 3,
              },
              {
                title: 'Created Date',
                dataField: 'created',
                type: DataTableType.DATE_TIME,
                mobileDisplay: true,
                width: 3,
              },
              {
                title: 'Created By',
                dataField: 'createdBy',
                dataProcess: {
                  action: DataHelper.getValueFromData,
                  data: props.users,
                  field: ValueTemplate.NAME,
                },
                type: DataTableType.STRING,
                mobileDisplay: false,
                width: 3,
              },
            ]}
          />
        ),
      },
    ],
  };

  const jobCodeSection = {
    fields: [
      {
        dataField: 'jobCodes',
        type: DataFormType.TABLE,
        content: (
          <DataTable
            title="Job ID Prefixes"
            titleIcon={<FontAwesomeIcon icon={faClipboard} />}
            description={
              'Add common Job ID prefixes to use for your Job IDs here.'
            }
            data={props.selected.jobCodes}
            dataType={DataType.JOB_CODES}
            primaryAction={DataActionType.CREATE_JOB_CODE}
            primaryActionTitle={'Add Job ID Prefix'}
            handleAction={handleDataAction}
            pageSize={5}
            sortField={['created']}
            sortDirection={[SortDirection.DESC]}
            actions={[
              {
                title: 'Edit',
                icon: 'pencil',
                className: 'spaceBelow',
                action: DataActionType.EDIT_JOB_CODE,
              },
              {
                title: 'Delete',
                icon: 'delete',
                className: 'redButton',
                action: DataActionType.DELETE_JOB_CODE,
              },
            ]}
            cols={[
              {
                title: 'Job ID Prefix',
                dataField: 'name',
                type: DataTableType.PLAIN,
                mobileDisplay: true,
                width: 6,
              },
              {
                title: 'Starting Number',
                dataField: 'startNumber',
                type: DataTableType.NUMBER,
                mobileDisplay: true,
                width: 3,
              },
              {
                title: 'Created By',
                dataField: 'createdBy',
                dataProcess: {
                  action: DataHelper.getValueFromData,
                  data: props.users,
                  field: ValueTemplate.NAME,
                },
                type: DataTableType.STRING,
                mobileDisplay: false,
                width: 3,
              },
            ]}
          />
        ),
      },
    ],
  };

  const leadSourceSection = {
    fields: [
      {
        dataField: 'leadSources',
        type: DataFormType.TABLE,
        content: (
          <DataTable
            title="Lead Sources"
            titleIcon={<FontAwesomeIcon icon={faLocationArrow} />}
            description={
              'Add common Lead Sources to help categorise your Jobs.'
            }
            data={props.selected.leadSources}
            dataType={DataType.LEAD_SOURCES}
            primaryAction={DataActionType.CREATE_LEAD_SOURCE}
            primaryActionTitle={'Add Lead Source'}
            handleAction={handleDataAction}
            pageSize={5}
            sortField={['created']}
            sortDirection={[SortDirection.DESC]}
            actions={[
              {
                title: 'Edit',
                icon: 'pencil',
                className: 'spaceBelow',
                action: DataActionType.EDIT_LEAD_SOURCE,
              },
              {
                title: 'Delete',
                icon: 'delete',
                className: 'redButton',
                action: DataActionType.DELETE_LEAD_SOURCE,
              },
            ]}
            cols={[
              {
                title: 'Lead Source',
                dataField: 'name',
                type: DataTableType.PLAIN,
                mobileDisplay: true,
                width: 6,
              },
              {
                title: 'Created By',
                dataField: 'createdBy',
                dataProcess: {
                  action: DataHelper.getValueFromData,
                  data: props.users,
                  field: ValueTemplate.NAME,
                },
                type: DataTableType.STRING,
                mobileDisplay: false,
                width: 3,
              },
            ]}
          />
        ),
      },
    ],
  };

  const staffAvailabilitySection = {
    fields: [
      {
        dataField: 'staffAvailability',
        type: DataFormType.TABLE,
        content: (
          <DataTable
            title="Staff Availability"
            titleIcon={<FontAwesomeIcon icon={faClock} />}
            description={
              'Add Staff Availability Logs to manage planned time off e.g. annual leave.'
            }
            data={props.selected.staffAvailability}
            dataType={DataType.STAFF_AVAILABILITY}
            primaryAction={DataActionType.CREATE_STAFF_AVAILABILITY}
            primaryActionTitle={'Add Staff Availability Log'}
            handleAction={handleDataAction}
            pageSize={5}
            sortField={['created']}
            sortDirection={[SortDirection.DESC]}
            actions={[
              {
                title: 'Edit',
                icon: 'pencil',
                className: 'spaceBelow',
                action: DataActionType.EDIT_STAFF_AVAILABILITY,
              },
              {
                title: 'Delete',
                icon: 'delete',
                className: 'redButton',
                action: DataActionType.DELETE_STAFF_AVAILABILITY,
              },
            ]}
            cols={[
              {
                title: 'Staff Member',
                dataField: 'userId',
                dataProcess: {
                  action: DataHelper.getValueFromData,
                  data: props.users,
                  field: ValueTemplate.NAME,
                },
                type: DataTableType.STRING,
                mobileDisplay: false,
                width: 3,
              },
              {
                title: 'Start Date',
                dataField: 'startDate',
                type: DataTableType.DATE_TIME,
                mobileDisplay: true,
                width: 3,
              },
              {
                title: 'End Date',
                dataField: 'endDate',
                type: DataTableType.DATE_TIME,
                mobileDisplay: true,
                width: 3,
              },
              {
                title: 'Description',
                dataField: 'description',
                type: DataTableType.PLAIN,
                mobileDisplay: true,
                width: 3,
              },
              {
                title: 'Created By',
                dataField: 'createdBy',
                dataProcess: {
                  action: DataHelper.getValueFromData,
                  data: props.users,
                  field: ValueTemplate.NAME,
                },
                type: DataTableType.STRING,
                mobileDisplay: false,
                width: 3,
              },
            ]}
          />
        ),
      },
    ],
  };

  const customFieldsSection = {
    fields: [
      {
        dataField: 'customFields',
        type: DataFormType.TABLE,
        content: (
          <DataTable
            title="Custom Fields"
            titleIcon={<FontAwesomeIcon icon={faKeyboard} />}
            description={`Add custom fields to Jobs to further extend Onsite7's functionality.`}
            data={props.selected.customFields}
            dataType={DataType.CUSTOM_FIELDS}
            primaryAction={DataActionType.CREATE_CUSTOM_FIELD}
            primaryActionTitle={'Add Custom Field'}
            handleAction={handleDataAction}
            pageSize={5}
            sortField={['created']}
            sortDirection={[SortDirection.DESC]}
            actions={[
              {
                title: 'Edit',
                icon: 'pencil',
                className: 'spaceBelow',
                action: DataActionType.EDIT_CUSTOM_FIELD,
              },
              {
                title: 'Delete',
                icon: 'delete',
                className: 'redButton',
                action: DataActionType.DELETE_CUSTOM_FIELD,
              },
            ]}
            cols={[
              {
                title: 'Custom Field',
                dataField: 'name',
                type: DataTableType.PLAIN,
                mobileDisplay: true,
                width: 6,
              },
              {
                title: 'Type',
                dataField: 'type',
                type: DataTableType.STRING,
                mobileDisplay: true,
                width: 3,
              },
              {
                title: 'Required',
                dataField: 'isRequired',
                type: DataTableType.STRING,
                mobileDisplay: true,
                width: 3,
              },
              {
                title: 'Created By',
                dataField: 'createdBy',
                dataProcess: {
                  action: DataHelper.getValueFromData,
                  data: props.users,
                  field: ValueTemplate.NAME,
                },
                type: DataTableType.STRING,
                mobileDisplay: false,
                width: 3,
              },
            ]}
          />
        ),
      },
    ],
  };

  const integrationSection = {
    hideSection: ![UserRole.SUPER_ADMIN, UserRole.MANAGER].includes(
      props.loggedInUser.role as UserRole,
    ),
    title: 'Add-ons',
    titleIcon: <FontAwesomeIcon icon={faCogs} />,
    fields: [
      {
        dataField: 'integrations',
        type: DataFormType.TABLE,
        content: (
          <>
            <XeroAuth company={props.selected} crudAction={props.crudAction} />

            <div style={{paddingTop: 10, paddingBottom: 10}} />
            <QuickbooksAuth
              company={props.selected}
              crudAction={props.crudAction}
            />
          </>
        ),
      },
    ],
  };

  const keapSection = {
    hideSection: ![UserRole.SUPER_ADMIN].includes(
      props.loggedInUser.role as UserRole,
    ),
    title: 'Keap Configuration',
    titleIcon: <FontAwesomeIcon icon={faCogs} />,
    fields: [
      {
        className: 'halfWidth',
        title: 'Keap Account ID',
        dataField: 'keap.id',
        type: DataFormType.TEXT,
        optional: true,
      },
      {
        className: 'halfWidth',
        title: 'Keap Account Name',
        dataField: 'keap.name',
        type: DataFormType.TEXT,
        optional: true,
      },
      {
        className: 'halfWidth',
        title: 'Keap API Key',
        dataField: 'keap.apiKey',
        type: DataFormType.TEXT,
        optional: true,
      },
      {
        className: 'halfWidth',
        title: 'Onsite7 Account ID',
        dataField: '_id',
        type: DataFormType.TEXT_READ_ONLY,
        optional: true,
      },
    ],
  };

  const sections: any = [companySection];

  if (props.loggedInUser.role === UserRole.SUPER_ADMIN) {
    sections.push(contactSection);
    sections.push(subscriptionSection);
    sections.push(stageTitleSection);
  }

  sections.push(themeSection);
  sections.push(checklistSection);
  sections.push(costSection);
  sections.push(jobCodeSection);
  sections.push(leadSourceSection);
  sections.push(staffAvailabilitySection);
  sections.push(customFieldsSection);
  sections.push(integrationSection);
  sections.push(keapSection);

  return (
    <DataModal
      showDataForm={props.showDataForm}
      onClose={resetDataForm}
      searchAction={props.searchAction}
      crudAction={async (
        action: CRUDType,
        dataType: DataType,
        data: any,
        completeAction = true,
        clearFilter: boolean = false,
      ) => {
        if (action === CRUDType.DELETE) {
          await props.actionHelper.handleAction(
            dataType,
            null,
            action,
            data._id,
          );
        } else {
          const result = await props.crudAction(
            data._id?.length ? CRUDType.UPDATE : action,
            dataType,
            data,
            completeAction,
            clearFilter,
          );

          props.setSelected(result.data);
        }
        props.resetDataType();
      }}
      title={props.selected.name}
      data={{
        selected: props.selected,
      }}
      dataType={DataType.COMPANY}
      sections={sections}
    />
  );
};

export default CompanyModal;
