import {
  CRUDType,
  DataActionType,
  DataFormType,
  Field,
  ValidationType,
} from '../components/Data/DataConst';
import config from '../config/config';
import {DataType} from '../const/crud';
import {
  Company,
  CompanyEnums,
  companyInitialState,
  CompanyThemeItemType,
} from '../models/Company';
import {CustomerEnums, customerInitialState} from '../models/Customer';
import {JobEnums, jobInitialState} from '../models/Job';
import {User, userInitialState, UserRole} from '../models/User';
import AxiosHelper from './axiosHelper';
import {ModalType} from './modalHelper';

export default class ActionHelper {
  private axiosHelper: AxiosHelper;

  constructor(
    token,
    private modalHelper: any,
    private formSetter: any,
    private loggedInUser: User,
    private loggedInCompany: Company,
  ) {
    this.axiosHelper = new AxiosHelper(token);
  }

  private getInitialState(dataType: DataType) {
    switch (dataType) {
      case DataType.JOB:
        return jobInitialState;
      case DataType.USER:
        return userInitialState;
      case DataType.COMPANY:
        return companyInitialState;
      case DataType.CUSTOMER:
        return customerInitialState;
    }

    return;
  }

  public async handleAction(
    dataType: DataType,
    dataSetter: any,
    action: CRUDType,
    id: string,
    data?: any,
    url?: string,
    callback?: any,
  ) {
    switch (action) {
      case CRUDType.CREATE:
        dataSetter({...this.getInitialState(dataType), ...data?.prefill});
        this.formSetter(true, action);
        break;
      case CRUDType.READ:
        const jobs = await this.axiosHelper.get(url || '');
        dataSetter(jobs.data);
        return jobs.data;
      case CRUDType.UPDATE:
        let adminUser;
        if (this.loggedInUser.role === UserRole.SUPER_ADMIN) {
          adminUser = `&adminUser=${this.loggedInUser._id}`;
        }

        const result = await this.axiosHelper.get(
          `${config.api}/${dataType}/${id}?company=${
            this.loggedInUser.company
          }${adminUser ? adminUser : ''}`,
        );

        dataSetter(result.data);

        this.formSetter(true, action);
        break;
      case CRUDType.DELETE:
        this.modalHelper.showModal(
          ModalType.DELETE_PROMPT,
          undefined,
          undefined,
          undefined,
          {
            action: {
              type: CRUDType.DELETE,
              documentId: id,
              dataType: dataType,
              data,
              callback: callback,
            },
          },
        );
        break;
      case CRUDType.CANCEL:
        this.modalHelper.showModal(
          ModalType.CANCEL_PROMPT,
          undefined,
          undefined,
          undefined,
          {
            action: {
              type: CRUDType.CANCEL,
              documentId: id,
              dataType: dataType,
              data,
              callback: callback,
            },
          },
        );
    }
  }

  public async handleDataAction(
    dataType: DataType,
    dataSetter: any,
    action: DataActionType,
    documentId: string,
    dataId: string,
    data?: any,
  ) {
    switch (action) {
      case DataActionType.CREATE_NOTE:
        const modalFields: Field[] = [
          {
            title: 'Note',
            dataField: 'note',
            type: DataFormType.TEXT_AREA,
            validation: [ValidationType.REQUIRED],
          },
        ];

        this.modalHelper.showModal(
          ModalType.CREATE_NOTE,
          {status: true, formType: CRUDType.CREATE},
          undefined,
          undefined,
          {
            action: {
              type: DataActionType.CREATE_NOTE,
              documentId,
              dataId,
              dataType,
              dataSetter,
              data,
            },
          },
          modalFields,
        );
        break;
      case DataActionType.DELETE_NOTE:
        this.modalHelper.showModal(
          ModalType.DELETE_NOTE_PROMPT,
          undefined,
          undefined,
          undefined,
          {
            action: {
              type: DataActionType.DELETE_NOTE,
              documentId,
              dataId,
              dataType,
              dataSetter,
              data,
            },
          },
        );
        break;
      case DataActionType.CREATE_MEDIA:
        this.modalHelper.showModal(
          ModalType.CREATE_MEDIA,
          {status: true, formType: CRUDType.CREATE},
          undefined,
          undefined,
          {
            action: {
              type: DataActionType.CREATE_MEDIA,
              documentId,
              dataId,
              dataType,
              dataSetter,
              data,
            },
          },
          [
            {
              title: 'Photo/Video Name',
              optional: true,
              dataField: 'title',
              type: DataFormType.TEXT,
            },
            {
              title: '',
              dataField: 'media',
              type: DataFormType.MEDIA_UPLOAD,
              validation: [ValidationType.REQUIRED],
            },
          ],
        );
        break;
      case DataActionType.DELETE_MEDIA:
        this.modalHelper.showModal(
          ModalType.DELETE_MEDIA_PROMPT,
          undefined,
          undefined,
          undefined,
          {
            action: {
              type: DataActionType.DELETE_MEDIA,
              documentId,
              dataId,
              dataType,
              dataSetter,
              data,
            },
          },
        );
        break;
      case DataActionType.CREATE_ATTACHMENT:
        this.modalHelper.showModal(
          ModalType.CREATE_ATTACHMENT,
          {status: true, formType: CRUDType.CREATE},
          undefined,
          undefined,
          {
            action: {
              type: DataActionType.CREATE_ATTACHMENT,
              documentId,
              dataId,
              dataType,
              dataSetter,
              data,
            },
          },
          [
            {
              title: 'Attachment Name',
              optional: true,
              dataField: 'title',
              type: DataFormType.TEXT,
            },
            {
              title: '',
              dataField: 'attachment',
              type: DataFormType.ATTACHMENT_UPLOAD,
              validation: [ValidationType.REQUIRED],
            },
          ],
        );
        break;
      case DataActionType.DELETE_ATTACHMENT:
        this.modalHelper.showModal(
          ModalType.DELETE_ATTACHMENT_PROMPT,
          undefined,
          undefined,
          undefined,
          {
            action: {
              type: DataActionType.DELETE_ATTACHMENT,
              documentId,
              dataId,
              dataType,
              dataSetter,
              data,
            },
          },
        );
        break;
      case DataActionType.CREATE_LOCATION:
        this.modalHelper.showModal(
          ModalType.CREATE_LOCATION,
          {status: true, formType: CRUDType.CREATE},
          undefined,
          undefined,
          {
            action: {
              type: DataActionType.CREATE_LOCATION,
              documentId,
              dataId,
              dataType,
              dataSetter,
              data,
            },
          },
          [
            {
              title: 'Location',
              dataField: 'location',
              type: DataFormType.TEXT,
              validation: [ValidationType.REQUIRED],
            },
          ],
        );
        break;
      case DataActionType.DELETE_LOCATION:
        this.modalHelper.showModal(
          ModalType.DELETE_LOCATION_PROMPT,
          undefined,
          undefined,
          undefined,
          {
            action: {
              type: DataActionType.DELETE_LOCATION,
              documentId,
              dataId,
              dataType,
              dataSetter,
              data,
            },
          },
        );
        break;
      case DataActionType.CREATE_THEME:
        this.modalHelper.showModal(
          ModalType.CREATE_THEME,
          {status: true, formType: CRUDType.CREATE},
          undefined,
          undefined,
          {
            action: {
              type: DataActionType.CREATE_THEME,
              documentId,
              dataId,
              dataType,
              dataSetter,
              data,
            },
          },
          [
            {
              title: 'Type',
              dataField: 'type',
              type: DataFormType.SELECT,
              dataSet: !data
                ? CompanyEnums.CompanyThemeOptions
                : CompanyEnums.CompanyThemeOptions.filter(
                    (t) => t.value !== CompanyThemeItemType.LOGO,
                  ),
              validation: [ValidationType.REQUIRED],
            },
            {
              title: '',
              dataField: 'media',
              type: DataFormType.MEDIA_UPLOAD,
              validation: [ValidationType.REQUIRED],
            },
          ],
        );
        break;
      case DataActionType.DELETE_THEME:
        this.modalHelper.showModal(
          ModalType.DELETE_THEME_PROMPT,
          undefined,
          undefined,
          undefined,
          {
            action: {
              type: DataActionType.DELETE_THEME,
              documentId,
              dataId,
              dataType,
              dataSetter,
              data,
            },
          },
        );
        break;
      case DataActionType.CREATE_CONTACT:
        const contactFields: Field[] = [
          {
            title: 'Mark as Primary Contact',
            dataField: 'contact.primaryContact',
            type: DataFormType.SELECT_BOOLEAN,
            dataSet: CustomerEnums.CustomerPrimaryContactOptions,
            validation: [ValidationType.REQUIRED],
          },
          {
            title: 'Title',
            dataField: 'contact.title',
            type: DataFormType.SELECT,
            dataSet: CustomerEnums.CustomerTitleOptions,
            validation: [ValidationType.REQUIRED],
          },
          {
            className: 'halfWidth',
            title: 'First Name',
            dataField: 'contact.firstName',
            type: DataFormType.TEXT,
            optional: true,
          },
          {
            className: 'halfWidth',
            title: 'Last Name',
            dataField: 'contact.lastName',
            type: DataFormType.TEXT,
            validation: [ValidationType.REQUIRED],
          },
          {
            className: 'halfWidth',
            title: 'Phone',
            dataField: 'contact.phone',
            type: DataFormType.TEXT,
            validation: [ValidationType.REQUIRED],
          },
          {
            className: 'halfWidth',
            title: 'Email',
            dataField: 'contact.email',
            type: DataFormType.TEXT_LOWER,
            optional: true,
          },
          {
            className: 'fullWidth',
            title: 'Search Address',
            dataField: 'contact.address.address1',
            type: DataFormType.ADDRESS_LOOKUP,
            optional: true,
          },
          {
            className: 'halfWidth',
            title: 'Address 1',
            dataField: 'contact.address.address1',
            type: DataFormType.TEXT,
            validation: [ValidationType.REQUIRED],
          },
          {
            className: 'halfWidth',
            title: 'Address 2',
            dataField: 'contact.address.address2',
            type: DataFormType.TEXT,
            optional: true,
          },
          {
            className: 'halfWidth',
            title: 'Town/City',
            dataField: 'contact.address.address3',
            type: DataFormType.TEXT,
            validation: [ValidationType.REQUIRED],
          },
          {
            className: 'halfWidth',
            title: 'Postcode',
            dataField: 'contact.address.postalCode',
            type: DataFormType.TEXT_UPPER,
            validation: [ValidationType.REQUIRED],
          },
        ];

        this.modalHelper.showModal(
          ModalType.CREATE_CONTACT,
          {status: true, formType: CRUDType.CREATE},
          undefined,
          undefined,
          {
            action: {
              type: DataActionType.CREATE_CONTACT,
              documentId,
              dataId,
              dataType,
              dataSetter,
              data,
            },
          },
          contactFields,
        );
        break;
      case DataActionType.EDIT_CONTACT:
        const contactEditFields: Field[] = [
          {
            title: 'Mark as Primary Contact',
            dataField: 'contact.primaryContact',
            type: DataFormType.SELECT_BOOLEAN,
            dataSet: CustomerEnums.CustomerPrimaryContactOptions,
            validation: [ValidationType.REQUIRED],
          },
          {
            title: 'Title',
            dataField: 'contact.title',
            type: DataFormType.SELECT,
            dataSet: CustomerEnums.CustomerTitleOptions,
            validation: [ValidationType.REQUIRED],
          },
          {
            className: 'halfWidth',
            title: 'First Name',
            dataField: 'contact.firstName',
            type: DataFormType.TEXT,
            optional: true,
          },
          {
            className: 'halfWidth',
            title: 'Last Name',
            dataField: 'contact.lastName',
            type: DataFormType.TEXT,
            validation: [ValidationType.REQUIRED],
          },
          {
            className: 'halfWidth',
            title: 'Phone',
            dataField: 'contact.phone',
            type: DataFormType.TEXT,
            validation: [ValidationType.REQUIRED],
          },
          {
            className: 'halfWidth',
            title: 'Email',
            dataField: 'contact.email',
            type: DataFormType.TEXT_LOWER,
            optional: true,
          },
          {
            className: 'fullWidth',
            title: 'Search Address',
            dataField: 'contact.address.address1',
            type: DataFormType.ADDRESS_LOOKUP,
            optional: true,
          },
          {
            className: 'halfWidth',
            title: 'Address 1',
            dataField: 'contact.address.address1',
            type: DataFormType.TEXT,
            validation: [ValidationType.REQUIRED],
          },
          {
            className: 'halfWidth',
            title: 'Address 2',
            dataField: 'contact.address.address2',
            type: DataFormType.TEXT,
            optional: true,
          },
          {
            className: 'halfWidth',
            title: 'Town/City',
            dataField: 'contact.address.address3',
            type: DataFormType.TEXT,
            validation: [ValidationType.REQUIRED],
          },
          {
            className: 'halfWidth',
            title: 'Postcode',
            dataField: 'contact.address.postalCode',
            type: DataFormType.TEXT_UPPER,
            validation: [ValidationType.REQUIRED],
          },
        ];
        this.modalHelper.showModal(
          ModalType.EDIT_CONTACT,
          {status: true, formType: CRUDType.UPDATE},
          undefined,
          undefined,
          {
            action: {
              type: DataActionType.EDIT_CONTACT,
              documentId,
              dataId,
              dataType,
              dataSetter,
              data,
            },
          },
          contactEditFields,
          data.contact.find((c) => c._id === dataId),
        );
        break;
      case DataActionType.DELETE_CONTACT:
        this.modalHelper.showModal(
          ModalType.DELETE_CONTACT_PROMPT,
          {
            status: true,
            formType: CRUDType.UPDATE,
          },
          undefined,
          undefined,
          {
            action: {
              type: DataActionType.DELETE_CONTACT,
              documentId,
              dataId,
              dataType,
              dataSetter,
              data,
            },
          },
        );
        break;
      case DataActionType.IMPORT_CHECKLIST_TEMPLATE:
        this.modalHelper.showModal(
          ModalType.IMPORT_CHECKLIST,
          {status: true, formType: CRUDType.UPDATE},
          undefined,
          undefined,
          {
            action: {
              type: DataActionType.IMPORT_CHECKLIST_TEMPLATE,
              documentId,
              dataId,
              dataType,
              dataSetter,
              data,
            },
          },
        );
        break;
      case DataActionType.CREATE_CHECKLIST:
        this.modalHelper.showModal(
          ModalType.CREATE_CHECKLIST,
          {status: true, formType: CRUDType.CREATE},
          undefined,
          undefined,
          {
            action: {
              type: DataActionType.CREATE_CHECKLIST,
              documentId,
              dataId,
              dataType,
              dataSetter,
              data,
            },
          },
          [
            {
              title: 'Status',
              dataField: 'checklist.status',
              type: DataFormType.SELECT,
              dataSet: [
                {
                  key: 'not-completed',
                  value: 'not-completed',
                  text: 'Not Completed',
                },
                {
                  key: 'completed',
                  value: 'completed',
                  text: 'Completed',
                },
              ],
              validation: [ValidationType.REQUIRED],
            },
            {
              title: 'Task',
              dataField: 'checklist.text',
              type: DataFormType.TEXT,
              validation: [ValidationType.REQUIRED],
            },
          ],
        );
        break;
      case DataActionType.DELETE_CHECKLIST:
        this.modalHelper.showModal(
          ModalType.DELETE_CHECKLIST_PROMPT,
          undefined,
          undefined,
          undefined,
          {
            action: {
              type: DataActionType.DELETE_CHECKLIST,
              documentId,
              dataId,
              dataType,
              dataSetter,
              data,
            },
          },
        );
        break;
      case DataActionType.EDIT_CHECKLIST:
        const checklistEditFields: Field[] = [
          {
            title: 'Status',
            dataField: 'checklist.status',
            type: DataFormType.SELECT,
            dataSet: [
              {
                key: 'completed',
                value: 'completed',
                text: 'Completed',
              },
              {
                key: 'not-completed',
                value: 'not-completed',
                text: 'Not Completed',
              },
            ],
            validation: [ValidationType.REQUIRED],
          },
          {
            title: 'Task',
            dataField: 'checklist.text',
            type: DataFormType.TEXT,
            validation: [ValidationType.REQUIRED],
          },
        ];

        this.modalHelper.showModal(
          ModalType.EDIT_CHECKLIST,
          {status: true, formType: CRUDType.UPDATE},
          undefined,
          undefined,
          {
            action: {
              type: DataActionType.EDIT_CHECKLIST,
              documentId,
              dataId,
              dataType,
              dataSetter,
              data,
            },
          },
          checklistEditFields,
          data.selected.stages[data.data.stage].checklist.find(
            (c) => c._id === dataId,
          ),
        );
        break;
      case DataActionType.CREATE_CHECKLIST_TEMPLATE:
        this.modalHelper.showModal(
          ModalType.CREATE_CHECKLIST_TEMPLATE,
          {status: true, formType: CRUDType.CREATE},
          undefined,
          undefined,
          {
            action: {
              type: DataActionType.CREATE_CHECKLIST_TEMPLATE,
              documentId,
              dataId,
              dataType,
              dataSetter,
              data,
            },
          },
          [
            {
              title: 'Checklist Name',
              dataField: 'checklist.name',
              type: DataFormType.TEXT,
              validation: [ValidationType.REQUIRED],
            },
            {
              title: 'Checklist Type',
              dataField: 'checklist.checklistType',
              type: DataFormType.TEXT,
              optional: true,
            },
            {
              dataField: 'checklist.tasks',
              type: DataFormType.CHECKLIST_TEMPLATE,
              dataSet: {
                loggedInUser: this.loggedInUser,
                data,
              },
            },
          ],
        );
        break;
      case DataActionType.DELETE_CHECKLIST_TEMPLATE:
        this.modalHelper.showModal(
          ModalType.DELETE_CHECKLIST_TEMPLATE_PROMPT,
          undefined,
          undefined,
          undefined,
          {
            action: {
              type: DataActionType.DELETE_CHECKLIST_TEMPLATE,
              documentId,
              dataId,
              dataType,
              dataSetter,
              data,
            },
          },
        );
        break;
      case DataActionType.EDIT_CHECKLIST_TEMPLATE:
        this.modalHelper.showModal(
          ModalType.EDIT_CHECKLIST_TEMPLATE,
          {status: true, formType: CRUDType.CREATE},
          undefined,
          undefined,
          {
            action: {
              type: DataActionType.EDIT_CHECKLIST_TEMPLATE,
              documentId,
              dataId,
              dataType,
              dataSetter,
              data,
            },
          },
          [
            {
              title: 'Checklist Name',
              dataField: 'checklist.name',
              type: DataFormType.TEXT,
              validation: [ValidationType.REQUIRED],
            },
            {
              title: 'Checklist Type',
              dataField: 'checklist.checklistType',
              type: DataFormType.TEXT,
              optional: true,
            },
            {
              dataField: 'checklist.tasks',
              type: DataFormType.CHECKLIST_TEMPLATE,
              dataSet: {
                loggedInUser: this.loggedInUser,
                data,
              },
            },
          ],
          data.checklists.find((i) => i._id === dataId),
        );
        break;

      case DataActionType.IMPORT_XERO_CUSTOMER:
        this.modalHelper.showModal(
          ModalType.IMPORT_XERO_CUSTOMER,
          {status: true, formType: CRUDType.UPDATE},
          undefined,
          undefined,
          {
            action: {
              type: DataActionType.IMPORT_XERO_CUSTOMER,
              documentId,
              dataId,
              dataType,
              dataSetter,
              data,
            },
          },
        );
        break;

      case DataActionType.IMPORT_QUICKBOOKS_CUSTOMER:
        this.modalHelper.showModal(
          ModalType.IMPORT_QUICKBOOKS_CUSTOMER,
          {status: true, formType: CRUDType.UPDATE},
          undefined,
          undefined,
          {
            action: {
              type: DataActionType.IMPORT_QUICKBOOKS_CUSTOMER,
              documentId,
              dataId,
              dataType,
              dataSetter,
              data,
            },
          },
        );
        break;

      case DataActionType.CREATE_PAYMENT:
        this.modalHelper.showModal(
          ModalType.CREATE_PAYMENT,
          {status: true, formType: CRUDType.CREATE},
          undefined,
          undefined,
          {
            action: {
              type: DataActionType.CREATE_PAYMENT,
              documentId,
              dataId,
              dataType,
              dataSetter,
              data,
            },
          },
          [
            {
              title: 'Payment Name or Number',
              description: 'E.G. Deposit, Part Payment, Final balance etc',
              dataField: 'pricing.paymentNumber',
              type: DataFormType.TEXT,
              validation: [ValidationType.REQUIRED],
            },
            {
              title: 'Payment Amount',
              dataField: 'pricing.amount',
              type: DataFormType.CURRENCY,
              validation: [ValidationType.REQUIRED],
            },
            {
              title: 'Payment Status',
              dataField: 'pricing.status',
              type: DataFormType.SELECT,
              dataSet: JobEnums.PricingStatusOptions,
              validation: [ValidationType.REQUIRED],
            },
            {
              title: 'Invoice Number',
              dataField: 'pricing.invoiceNumber',
              type: DataFormType.TEXT,
              optional: true,
            },
            {
              title: 'Description',
              dataField: 'pricing.description',
              type: DataFormType.TEXT_AREA,
              optional: true,
            },
          ],
        );
        break;
      case DataActionType.EDIT_PAYMENT:
        this.modalHelper.showModal(
          ModalType.EDIT_PAYMENT,
          {status: true, formType: CRUDType.CREATE},
          undefined,
          undefined,
          {
            action: {
              type: DataActionType.EDIT_PAYMENT,
              documentId,
              dataId,
              dataType,
              dataSetter,
              data,
            },
          },
          [
            {
              title: 'Payment Name or Number',
              description: 'E.G. Deposit, Part Payment, Final balance etc',
              dataField: 'pricing.paymentNumber',
              type: DataFormType.TEXT,
              validation: [ValidationType.REQUIRED],
            },
            {
              title: 'Payment Amount',
              dataField: 'pricing.amount',
              type: DataFormType.CURRENCY,
              validation: [ValidationType.REQUIRED],
            },
            {
              title: 'Payment Status',
              dataField: 'pricing.status',
              type: DataFormType.SELECT,
              dataSet: JobEnums.PricingStatusOptions,
              validation: [ValidationType.REQUIRED],
            },
            {
              title: 'Invoice Number',
              dataField: 'pricing.invoiceNumber',
              type: DataFormType.TEXT,
              optional: true,
            },
            {
              title: 'Description',
              dataField: 'pricing.description',
              type: DataFormType.TEXT_AREA,
              optional: true,
            },
          ],
          data.selected.pricing.find((i) => i._id === dataId),
        );
        break;
      case DataActionType.DELETE_PAYMENT:
        this.modalHelper.showModal(
          ModalType.DELETE_LOCATION_PROMPT,
          undefined,
          undefined,
          undefined,
          {
            action: {
              type: DataActionType.DELETE_PAYMENT,
              documentId,
              dataId,
              dataType,
              dataSetter,
              data,
            },
          },
        );
        break;

      case DataActionType.CREATE_COST_GROUP:
        this.modalHelper.showModal(
          ModalType.CREATE_COST_GROUP,
          {status: true, formType: CRUDType.CREATE},
          undefined,
          undefined,
          {
            action: {
              type: DataActionType.CREATE_COST_GROUP,
              documentId,
              dataId,
              dataType,
              dataSetter,
              data,
            },
          },
          [
            {
              title: 'Cost Group Name',
              dataField: 'costGroup.name',
              type: DataFormType.TEXT,
              validation: [ValidationType.REQUIRED],
            },
            {
              dataField: 'costGroup.costs',
              type: DataFormType.COST_GROUP,
              dataSet: {
                loggedInUser: this.loggedInUser,
                loggedInCompany: this.loggedInCompany,
                data,
              },
            },
          ],
        );
        break;
      case DataActionType.DELETE_COST_GROUP:
        this.modalHelper.showModal(
          ModalType.DELETE_COST_GROUP_PROMPT,
          undefined,
          undefined,
          undefined,
          {
            action: {
              type: DataActionType.DELETE_COST_GROUP,
              documentId,
              dataId,
              dataType,
              dataSetter,
              data,
            },
          },
        );
        break;
      case DataActionType.EDIT_COST_GROUP:
        this.modalHelper.showModal(
          ModalType.EDIT_COST_GROUP,
          {status: true, formType: CRUDType.CREATE},
          undefined,
          undefined,
          {
            action: {
              type: DataActionType.EDIT_COST_GROUP,
              documentId,
              dataId,
              dataType,
              dataSetter,
              data,
            },
          },
          [
            {
              title: 'Cost Group Name',
              dataField: 'costGroup.name',
              type: DataFormType.TEXT,
              validation: [ValidationType.REQUIRED],
            },
            {
              dataField: 'costGroup.costs',
              type: DataFormType.COST_GROUP,
              dataSet: {
                loggedInUser: this.loggedInUser,
                loggedInCompany: this.loggedInCompany,
                data,
              },
            },
          ],
          data.costGroups.find((i) => i._id === dataId),
        );
        break;

      case DataActionType.IMPORT_COST_GROUP:
        this.modalHelper.showModal(
          ModalType.IMPORT_COST_GROUP,
          {status: true, formType: CRUDType.UPDATE},
          undefined,
          undefined,
          {
            action: {
              type: DataActionType.IMPORT_COST_GROUP,
              documentId,
              dataId,
              dataType,
              dataSetter,
              data,
            },
          },
        );
        break;

      case DataActionType.CREATE_COST:
        this.modalHelper.showModal(
          ModalType.CREATE_COST,
          {status: true, formType: CRUDType.CREATE},
          undefined,
          undefined,
          {
            action: {
              type: DataActionType.CREATE_COST,
              documentId,
              dataId,
              dataType,
              dataSetter,
              data,
            },
          },
          [
            {
              title: 'Name',
              dataField: 'cost.name',
              type: DataFormType.TEXT,
              validation: [ValidationType.REQUIRED],
            },
            {
              title: 'Net Amount',
              dataField: 'cost.amount',
              type: DataFormType.CURRENCY,
              validation: [ValidationType.REQUIRED],
            },
            {
              title: 'Stage',
              dataField: 'cost.stage',
              type: DataFormType.SELECT,
              dataSet: JobEnums.JobStageOptions(
                this.loggedInCompany.stageTitles,
              ),
              validation: [ValidationType.REQUIRED],
            },
            {
              title: 'Description',
              dataField: 'cost.description',
              type: DataFormType.TEXT,
              optional: true,
            },
            {
              title: 'Supplier',
              dataField: 'cost.company',
              type: DataFormType.TEXT,
              optional: true,
            },
            {
              title: 'Invoice Number',
              dataField: 'cost.invoiceNumber',
              type: DataFormType.TEXT,
              optional: true,
            },
          ],
        );
        break;
      case DataActionType.DELETE_COST:
        this.modalHelper.showModal(
          ModalType.DELETE_COST_PROMPT,
          undefined,
          undefined,
          undefined,
          {
            action: {
              type: DataActionType.DELETE_COST,
              documentId,
              dataId,
              dataType,
              dataSetter,
              data,
            },
          },
        );
        break;

      case DataActionType.EDIT_COST:
        const costEditFields: Field[] = [
          {
            title: 'Name',
            dataField: 'cost.name',
            type: DataFormType.TEXT,
            validation: [ValidationType.REQUIRED],
          },
          {
            title: 'Net Amount',
            dataField: 'cost.amount',
            type: DataFormType.CURRENCY,
            validation: [ValidationType.REQUIRED],
          },
          {
            title: 'Stage',
            dataField: 'cost.stage',
            type: DataFormType.SELECT,
            dataSet: JobEnums.JobStageOptions(this.loggedInCompany.stageTitles),
            validation: [ValidationType.REQUIRED],
          },
          {
            title: 'Description',
            dataField: 'cost.description',
            type: DataFormType.TEXT,
            optional: true,
          },
          {
            title: 'Supplier',
            dataField: 'cost.company',
            type: DataFormType.TEXT,
            optional: true,
          },
          {
            title: 'Invoice Number',
            dataField: 'cost.invoiceNumber',
            type: DataFormType.TEXT,
            optional: true,
          },
        ];

        this.modalHelper.showModal(
          ModalType.EDIT_COST,
          {status: true, formType: CRUDType.UPDATE},
          undefined,
          undefined,
          {
            action: {
              type: DataActionType.EDIT_COST,
              documentId,
              dataId,
              dataType,
              dataSetter,
              data,
            },
          },
          costEditFields,
          data.selected.costs.find((c) => c._id === dataId),
        );
        break;

      case DataActionType.CREATE_JOB_CODE:
        this.modalHelper.showModal(
          ModalType.CREATE_JOB_CODE,
          {status: true, formType: CRUDType.CREATE},
          undefined,
          undefined,
          {
            action: {
              type: DataActionType.CREATE_JOB_CODE,
              documentId,
              dataId,
              dataType,
              dataSetter,
              data,
            },
          },
          [
            {
              title: 'Job ID Prefix',
              dataField: 'jobCodes.name',
              type: DataFormType.TEXT,
              validation: [ValidationType.REQUIRED],
            },
            {
              title: 'Starting Count Number',
              dataField: 'jobCodes.startNumber',
              type: DataFormType.NUMBER,
              optional: true,
            },
          ],
        );
        break;
      case DataActionType.DELETE_JOB_CODE:
        this.modalHelper.showModal(
          ModalType.DELETE_JOB_CODE_PROMPT,
          undefined,
          undefined,
          undefined,
          {
            action: {
              type: DataActionType.DELETE_JOB_CODE,
              documentId,
              dataId,
              dataType,
              dataSetter,
              data,
            },
          },
        );
        break;
      case DataActionType.EDIT_JOB_CODE:
        this.modalHelper.showModal(
          ModalType.EDIT_JOB_CODE,
          {status: true, formType: CRUDType.CREATE},
          undefined,
          undefined,
          {
            action: {
              type: DataActionType.EDIT_JOB_CODE,
              documentId,
              dataId,
              dataType,
              dataSetter,
              data,
            },
          },
          [
            {
              title: 'Job ID Prefix',
              dataField: 'jobCode.name',
              type: DataFormType.TEXT,
              validation: [ValidationType.REQUIRED],
            },
            {
              title: 'Starting Count Number',
              dataField: 'jobCode.startNumber',
              type: DataFormType.NUMBER,
              optional: true,
            },
          ],
          data.jobCodes.find((i) => i._id === dataId),
        );
        break;

      case DataActionType.CREATE_LEAD_SOURCE:
        this.modalHelper.showModal(
          ModalType.CREATE_LEAD_SOURCE,
          {status: true, formType: CRUDType.CREATE},
          undefined,
          undefined,
          {
            action: {
              type: DataActionType.CREATE_LEAD_SOURCE,
              documentId,
              dataId,
              dataType,
              dataSetter,
              data,
            },
          },
          [
            {
              title: 'Lead Source',
              dataField: 'leadSource.name',
              type: DataFormType.TEXT,
              validation: [ValidationType.REQUIRED],
            },
          ],
        );
        break;
      case DataActionType.DELETE_LEAD_SOURCE:
        this.modalHelper.showModal(
          ModalType.DELETE_LEAD_SOURCE_PROMPT,
          undefined,
          undefined,
          undefined,
          {
            action: {
              type: DataActionType.DELETE_LEAD_SOURCE,
              documentId,
              dataId,
              dataType,
              dataSetter,
              data,
            },
          },
        );
        break;
      case DataActionType.EDIT_LEAD_SOURCE:
        this.modalHelper.showModal(
          ModalType.EDIT_LEAD_SOURCE,
          {status: true, formType: CRUDType.CREATE},
          undefined,
          undefined,
          {
            action: {
              type: DataActionType.EDIT_LEAD_SOURCE,
              documentId,
              dataId,
              dataType,
              dataSetter,
              data,
            },
          },
          [
            {
              title: 'Lead Source',
              dataField: 'leadSource.name',
              type: DataFormType.TEXT,
              validation: [ValidationType.REQUIRED],
            },
          ],
          data.leadSources.find((i) => i._id === dataId),
        );
        break;

      case DataActionType.CREATE_STAFF_AVAILABILITY:
        this.modalHelper.showModal(
          ModalType.CREATE_STAFF_AVAILABILITY,
          {status: true, formType: CRUDType.CREATE},
          undefined,
          undefined,
          {
            action: {
              type: DataActionType.CREATE_STAFF_AVAILABILITY,
              documentId,
              dataId,
              dataType,
              dataSetter,
              data,
            },
          },
          [
            {
              title: 'Staff Member',
              dataField: 'staffAvailability.userId',
              dataSet:
                data.searchOptions[DataType.USER] &&
                data.searchOptions[DataType.USER].length
                  ? data.searchOptions[DataType.USER]
                  : data.userOptions,
              dataType: DataType.USER,
              type: DataFormType.SEARCH_SELECT_API,
              validation: [ValidationType.REQUIRED],
            },
            {
              title: 'Start Date',
              dataField: 'staffAvailability.startDate',
              type: DataFormType.DATE_TIME,
              validation: [ValidationType.REQUIRED],
            },
            {
              title: 'End Date',
              dataField: 'staffAvailability.endDate',
              type: DataFormType.DATE_TIME,
              validation: [ValidationType.REQUIRED],
            },
            {
              title: 'Description',
              dataField: 'staffAvailability.description',
              type: DataFormType.TEXT_AREA,
              validation: [ValidationType.REQUIRED],
            },
          ],
        );
        break;
      case DataActionType.DELETE_STAFF_AVAILABILITY:
        this.modalHelper.showModal(
          ModalType.DELETE_STAFF_AVAILABILITY,
          undefined,
          undefined,
          undefined,
          {
            action: {
              type: DataActionType.DELETE_STAFF_AVAILABILITY,
              documentId,
              dataId,
              dataType,
              dataSetter,
              data,
            },
          },
        );
        break;
      case DataActionType.EDIT_STAFF_AVAILABILITY:
        this.modalHelper.showModal(
          ModalType.EDIT_STAFF_AVAILABILITY,
          {status: true, formType: CRUDType.CREATE},
          undefined,
          undefined,
          {
            action: {
              type: DataActionType.EDIT_STAFF_AVAILABILITY,
              documentId,
              dataId,
              dataType,
              dataSetter,
              data,
            },
          },
          [
            {
              title: 'Staff Member',
              dataField: 'staffAvailability.userId',
              dataSet:
                data.searchOptions[DataType.USER] &&
                data.searchOptions[DataType.USER].length
                  ? data.searchOptions[DataType.USER]
                  : data.userOptions,
              dataType: DataType.USER,
              type: DataFormType.SEARCH_SELECT_API,
              validation: [ValidationType.REQUIRED],
            },
            {
              title: 'Start Date',
              dataField: 'staffAvailability.startDate',
              type: DataFormType.DATE_TIME,
              validation: [ValidationType.REQUIRED],
            },
            {
              title: 'End Date',
              dataField: 'staffAvailability.endDate',
              type: DataFormType.DATE_TIME,
              validation: [ValidationType.REQUIRED],
            },
            {
              title: 'Description',
              dataField: 'staffAvailability.description',
              type: DataFormType.TEXT_AREA,
              validation: [ValidationType.REQUIRED],
            },
          ],
          data.selected.staffAvailability.find((i) => i._id === dataId),
        );
        break;

      case DataActionType.CREATE_CUSTOM_FIELD:
        const selectOpts = [];

        this.modalHelper.showModal(
          ModalType.CREATE_CUSTOM_FIELD,
          {status: true, formType: CRUDType.CREATE},
          undefined,
          undefined,
          {
            action: {
              type: DataActionType.CREATE_CUSTOM_FIELD,
              documentId,
              dataId,
              dataType,
              dataSetter,
              data,
            },
          },
          [
            {
              title: 'Field Name',
              dataField: 'customField.name',
              type: DataFormType.TEXT,
              validation: [ValidationType.REQUIRED],
            },
            {
              title: 'Field Type',
              dataField: 'customField.type',
              type: DataFormType.SELECT,
              dataSet: CompanyEnums.CompanyCustomFieldTypeOptions,
              validation: [ValidationType.REQUIRED],
            },
            {
              showIf: {field: 'customField.type', value: DataFormType.SELECT},
              title: 'Select Options',
              dataField: 'customField.selectOptions',
              type: DataFormType.SELECT_MULTIPLE,
              handleAddition: (add) =>
                selectOpts.push({
                  value: add.value,
                  text: add.value,
                  key: add.value,
                }),
            },
            {
              title: 'Is Field Required?',
              dataField: 'customField.isRequired',
              type: DataFormType.SELECT_BOOLEAN,
              dataSet: CompanyEnums.CompanyCustomFieldRequiredOptions,
              validation: [ValidationType.REQUIRED],
            },
          ],
        );
        break;
      case DataActionType.DELETE_CUSTOM_FIELD:
        this.modalHelper.showModal(
          ModalType.DELETE_CUSTOM_FIELD_PROMPT,
          undefined,
          undefined,
          undefined,
          {
            action: {
              type: DataActionType.DELETE_CUSTOM_FIELD,
              documentId,
              dataId,
              dataType,
              dataSetter,
              data,
            },
          },
        );
        break;
      case DataActionType.EDIT_CUSTOM_FIELD:
        this.modalHelper.showModal(
          ModalType.EDIT_CUSTOM_FIELD,
          {status: true, formType: CRUDType.CREATE},
          undefined,
          undefined,
          {
            action: {
              type: DataActionType.EDIT_CUSTOM_FIELD,
              documentId,
              dataId,
              dataType,
              dataSetter,
              data,
            },
          },
          [
            {
              title: 'Field Name',
              dataField: 'customField.name',
              type: DataFormType.TEXT,
              validation: [ValidationType.REQUIRED],
            },
            {
              title: 'Field Type',
              dataField: 'customField.type',
              type: DataFormType.SELECT,
              dataSet: CompanyEnums.CompanyCustomFieldTypeOptions,
              validation: [ValidationType.REQUIRED],
            },
            {
              showIf: {field: 'customField.type', value: DataFormType.SELECT},
              title: 'Select Options',
              dataField: 'customField.selectOptions',
              type: DataFormType.SELECT_MULTIPLE,
              handleAddition: (add) =>
                selectOpts.push({
                  value: add.value,
                  text: add.value,
                  key: add.value,
                }),
            },
            {
              title: 'Is Field Required?',
              dataField: 'customField.isRequired',
              type: DataFormType.SELECT_BOOLEAN,
              dataSet: CompanyEnums.CompanyCustomFieldRequiredOptions,
              validation: [ValidationType.REQUIRED],
            },
          ],
          data.customFields.find((i) => i._id === dataId),
        );
        break;
    }
  }
}
