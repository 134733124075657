import {faTasks} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {cloneDeep, uniqBy} from 'lodash';
import moment from 'moment';
import React, {useCallback, useEffect, useState} from 'react';
import {DropdownItemProps} from 'semantic-ui-react';
import {
  CRUDType,
  DataFormType,
  DataTableType,
  Section,
} from '../components/Data/DataConst';
import DataFilter from '../components/Data/DataFilter';
import DataTable, {SortOrder} from '../components/Data/DataTable';
import FileImport from '../components/FileImport';
import JobModal from '../components/Modal/JobModal';
import Welcome from '../components/Welcome';
import config from '../config/config';
import {DataSubType, DataType} from '../const/crud';
import {
  Job,
  JobEnums,
  jobInitialState,
  JobStage,
  jobStageMap,
} from '../models/Job';
import {UserRole} from '../models/User';
import ActionHelper from '../utils/actionHelper';
import {DataHelper, ValueTemplate} from '../utils/dataHelper';
import {SocketState} from '../utils/socketHelper';
import {PageProps} from './Page';

export interface JobPageProps extends PageProps {}

const Jobs: React.FunctionComponent<JobPageProps> = (props: JobPageProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activeDataType, setActiveDataType] = useState(DataType.JOB);

  const [filters, setFilters] = useState<any>({});
  const [searchedValue, setSearchedValue] = useState<any>();
  const [searchOptions, setSearchOptions] = useState({});
  const [page, setPage] = useState(1);
  const [calendarData, setCalendarData] = useState<any>();
  const [loadingCalendarData, setLoadingCalendarData] = useState(true);
  const [loadedCalendarData, setLoadedCalendarData] = useState(true);
  const [calendarDates, setCalendarDates] = useState({
    start: undefined,
    end: undefined,
  });
  const [importOpen, setImportOpen] = useState(false);
  const [sortOrder, setSortOrder] = useState(SortOrder.NEWEST_FIRST);

  const [hasSetSetters, setHasSetSetters] = useState(false);

  // Filter
  const [hideFilter, setHideFilter] = useState<boolean>(false);

  // Companies
  const [companies, setCompanies] = useState<any>();
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [loadedCompanies, setLoadedCompanies] = useState(false);
  const [companyOptions, setCompanyOptions] = useState<any>();

  // Jobs
  const [jobs, setJobs] = useState<any>();
  const [loadingJobs, setLoadingJobs] = useState(false);
  const [loadedJobs, setLoadedJobs] = useState(false);
  const [jobTotal, setJobTotal] = useState(0);
  const [loadingTotal, setLoadingTotal] = useState(true);
  const [loadedTotal, setLoadedTotal] = useState(true);

  // Customers
  const [customers, setCustomers] = useState<any>();
  const [loadingCustomers, setLoadingCustomers] = useState(false);
  const [loadedCustomers, setLoadedCustomers] = useState(true);
  const [customerOptions, setCustomerOptions] = useState<any>();

  // Users
  const [users, setUsers] = useState<any>();
  const [userOptions, setUserOptions] = useState<any>();
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [loadedUsers, setLoadedUsers] = useState(true);

  // Metrics
  const [metrics, setMetrics] = useState<any>();
  const [loadingMetrics, setLoadingMetrics] = useState<boolean>(false);
  const [loadedMetrics, setLoadedMetrics] = useState<boolean>(true);

  const [selectedJob, setSelectedJob] = useState(jobInitialState);

  const loadTotals = () => {
    setLoadedTotal(false);
    setLoadingTotal(false);
  };

  const formatFilters = useCallback(() => {
    if (!props.loggedInUser._id) {
      return;
    }

    const clonedFilters = cloneDeep(filters) || {};

    if (calendarDates && calendarDates.start && calendarDates.end) {
      clonedFilters['stage.start'] = `${moment(
        calendarDates.start,
      ).toISOString()} - ${moment(calendarDates.end).toISOString()}`;
    }

    if (clonedFilters.created) {
      const split = clonedFilters.created.split(' - ');
      clonedFilters.created = {
        $gte: split[0],
        $lte: split[1],
      };
    }

    if (props.loggedInUser.role === UserRole.SALES) {
      clonedFilters['stage.assignedTo'] = props.loggedInUser._id;
    }

    return createStageRangeFilters(clonedFilters);
  }, [calendarDates, filters, props.loggedInUser]);

  useEffect(() => {
    if (!hasSetSetters && loadedJobs && loadedUsers && customers && jobs) {
      setHasSetSetters(true);
      const setters = {
        [DataType.CUSTOMER]: {
          collection: setCustomers,
        },
        [DataType.JOB]: {
          selected: setSelectedJob,
          collection: (jobs) => {
            setJobs(jobs);
            setLoadingCalendarData(false);
            setLoadedCalendarData(false);
          },
          total: loadTotals,
        },
      };

      if ([props.loggedInUser.role === UserRole.SUPER_ADMIN]) {
        setters[DataType.COMPANY] = {
          collection: setCompanies,
        };
      }

      props.helpers.socket.addSetters(setters);

      const socketState: SocketState = {};
      socketState[DataType.CUSTOMER] = {
        collection: customers,
      };
      socketState[DataType.JOB] = {
        selected: selectedJob,
        collection: jobs,
      };

      if ([props.loggedInUser.role === UserRole.SUPER_ADMIN]) {
        socketState[DataType.COMPANY] = {
          collection: companies,
        };
      }

      props.helpers.socket.addState(socketState);
    }
  }, [
    hasSetSetters,
    props.loggedInUser.role,
    props.helpers.socket,
    loadedJobs,
    loadedCompanies,
    loadedUsers,
    companies,
    customers,
    jobs,
    selectedJob,
  ]);

  useEffect(() => {
    // Get jobs
    if (!loadedJobs && !loadingJobs && props.loggedInUser.company) {
      setLoadingJobs(true);

      const getJobs = async () => {
        const jobsResult = (
          await props.helpers.crud.run(CRUDType.READ, DataType.JOB, undefined, {
            sort: {created: sortOrder === SortOrder.NEWEST_FIRST ? -1 : 1},
            page: calendarDates && calendarDates.start ? 1 : page,
            pageSize: calendarDates && calendarDates.start ? 99 : 10,
            ...formatFilters(),
          })
        ).data;

        setJobs(jobsResult);
        props.helpers.socket.updateState(
          DataType.JOB,
          DataSubType.COLLECTION,
          jobsResult,
        );

        // Get customers
        setLoadingCustomers(false);
        setLoadedCustomers(false);

        setLoadedJobs(true);
        setLoadingJobs(false);

        // Get new total
        setLoadingTotal(false);
        setLoadedTotal(false);
      };
      getJobs();
    }
  }, [
    calendarDates,
    page,
    props.loggedInUser,
    props.helpers.crud,
    props.helpers.socket,
    filters,
    jobs,
    loadingJobs,
    loadedJobs,
    sortOrder,
    formatFilters,
  ]);

  // Get Total
  useEffect(() => {
    if (!loadingTotal && !loadedTotal && jobs) {
      setLoadingTotal(true);

      const clonedFilters = cloneDeep(filters);
      if (props.loggedInUser.role === UserRole.SALES) {
        clonedFilters['stage.assignedTo'] = props.loggedInUser._id;
      }

      const processed: any = createStageRangeFilters(clonedFilters);
      if (processed.created) {
        const split = processed.created.split(' - ');
        processed.created = {
          $gte: split[0],
          $lte: split[1],
        };
      }

      const getTotal = async () => {
        try {
          const totalUrl = `${config.api}/${DataType.JOB}/total`;
          setJobTotal(
            (
              await props.helpers.crud.run(
                CRUDType.READ,
                DataType.JOB,
                totalUrl,
                {
                  page,
                  pageSize: 10,
                  ...processed,
                },
              )
            ).data.total,
          );
        } catch (err) {}

        setLoadedTotal(true);
        setLoadingTotal(false);

        // Get new metrics
        setLoadingMetrics(false);
        setLoadedMetrics(false);
      };
      getTotal();
    }
  }, [
    props.helpers.crud,
    filters,
    page,
    loadingTotal,
    loadedTotal,
    jobs,
    props.loggedInUser,
  ]);

  // Get Metrics
  useEffect(() => {
    if (!loadingMetrics && !loadedMetrics) {
      setLoadingMetrics(true);

      const clonedFilters = cloneDeep(filters);
      if (props.loggedInUser.role === UserRole.SALES) {
        clonedFilters['stage.assignedTo'] = props.loggedInUser._id;
      }

      const processed: any = createStageRangeFilters(clonedFilters);

      if (processed.created) {
        const split = processed.created.split(' - ');
        processed.created = {
          $gte: split[0],
          $lte: split[1],
        };
      }

      const getMetrics = async () => {
        const metricUrl = `${config.api}/metric/${DataType.JOB}/total`;

        const metrics = (
          await props.helpers.crud.run(CRUDType.READ, DataType.JOB, metricUrl, {
            page,
            pageSize: 10,
            ...processed,
          })
        ).data;

        setMetrics(metrics);

        setLoadedMetrics(true);
        setLoadingMetrics(false);
      };
      getMetrics();
    }
  }, [
    filters,
    jobs,
    loadedMetrics,
    loadingMetrics,
    page,
    props.helpers.crud,
    props.loggedInUser,
  ]);

  // Get companies
  useEffect(() => {
    if (jobs && !loadedCompanies && !loadingCompanies) {
      setLoadingCompanies(true);
      const getCompanies = async () => {
        const companyIds: string[] = [];

        (jobs || []).forEach((job: Job) => {
          companyIds.push(job.company);
        });

        const companyResult = (
          await props.helpers.crud.run(
            CRUDType.READ,
            DataType.COMPANY,
            undefined,
            {page: 1, pageSize: 30, _id: {$in: companyIds}},
          )
        ).data;

        setCompanies(companyResult);

        // Create Options
        setCompanyOptions(DataHelper.buildCompanySelectOptions(companyResult));

        setLoadedCompanies(true);
        setLoadingCompanies(false);
      };
      getCompanies();
    }
  }, [props.helpers.crud, jobs, loadedCompanies, loadingCompanies]);

  // Get customers
  useEffect(() => {
    if (jobs && !loadedCustomers && !loadingCustomers) {
      setLoadingCustomers(true);
      const getCustomers = async () => {
        const customerIds: string[] = [];

        jobs.forEach((job: Job) => {
          customerIds.push(job.customer);
        });

        const customerResult = (
          await props.helpers.crud.run(
            CRUDType.READ,
            DataType.CUSTOMER,
            undefined,
            {page: 1, pageSize: 99, _id: {$in: uniqBy(customerIds)}},
          )
        ).data;

        setCustomers(customerResult);

        // Create Options
        setCustomerOptions(
          DataHelper.buildUserSelectOptions(
            customerResult,
            [
              {title: 'Company', data: 'companyName'},
              {title: 'Account Number', data: 'account', toUpper: true},
              {title: 'Postcode(s)', data: 'postalCode', toUpper: true},
            ],
            true,
          ),
        );

        // Get users
        setLoadingUsers(false);
        setLoadedUsers(false);

        setLoadedCustomers(true);
        setLoadingCustomers(false);
      };
      getCustomers();
    }
  }, [props.helpers.crud, jobs, loadingCustomers, loadedCustomers]);

  // Get users
  useEffect(() => {
    if (jobs && customers && !loadedUsers && !loadingUsers) {
      setLoadingUsers(true);
      const getUsers = async () => {
        let userIds: string[] = [props.loggedInUser._id];

        jobs.forEach((job: Job) => {
          if (job.attachments.length) {
            userIds = userIds.concat(
              job.attachments.map((att) => att.createdBy),
            );
          }

          if (job.locations.length) {
            userIds = userIds.concat(job.locations.map((loc) => loc.createdBy));
          }

          Object.keys(job.stages).forEach((stage) => {
            if (job.stages[stage].assignedTo) {
              userIds = userIds.concat(job.stages[stage].assignedTo);
            }

            if (job.stages[stage].notes.length) {
              userIds = userIds.concat(
                job.stages[stage].notes.map((note) => note.createdBy),
              );
            }

            if (job.stages[stage].media.length) {
              userIds = userIds.concat(
                job.stages[stage].media.map((media) => media.createdBy),
              );
            }

            if (job.stages[stage].signature.length) {
              userIds = userIds.concat(
                job.stages[stage].signature.map(
                  (signature) => signature.createdBy,
                ),
              );
            }
          });
        });

        const userResult = (
          await props.helpers.crud.run(
            CRUDType.READ,
            DataType.USER,
            undefined,
            {
              page: 1,
              pageSize: 30,
              _id: {$in: uniqBy(userIds)},
              allowArchived: true,
            },
          )
        ).data;

        setUsers(userResult);

        // Create Options
        setUserOptions(
          DataHelper.buildUserSelectOptions(userResult, [
            {
              title: 'Position',
              data: 'position',
            },
          ]),
        );

        // Load calendar data
        setLoadedCalendarData(false);
        setLoadingCalendarData(false);

        setLoadedUsers(true);
        setLoadingUsers(false);
      };
      getUsers();
    }
  }, [
    props.loggedInUser._id,
    props.helpers.crud,
    jobs,
    customers,
    loadingUsers,
    loadedUsers,
  ]);

  // Set calendar data
  useEffect(() => {
    if (
      !loadingCalendarData &&
      !loadedCalendarData &&
      jobs &&
      customers &&
      users
    ) {
      setLoadingCalendarData(true);
      const events = DataHelper.buildEventsFromJobs(
        jobs,
        customers,
        users,
        props.loggedInCompany,
      );

      setCalendarData(events);
      setLoadedCalendarData(true);
      setLoadingCalendarData(false);
    }
  }, [
    loadingCalendarData,
    loadedCalendarData,
    jobs,
    customers,
    users,
    props.loggedInCompany,
  ]);

  // Set company
  jobInitialState.company = props.loggedInUser.company;

  const actionHelper = new ActionHelper(
    props.authToken,
    props.helpers.modal,
    props.setDataForm,
    props.loggedInUser,
    props.loggedInCompany,
  );

  const handleAction = async (action: CRUDType, id: string, data: any) => {
    if (action === CRUDType.UPDATE) {
      // Get company if super admin and doesn't have it
      if (props.loggedInUser.role === UserRole.SUPER_ADMIN) {
        const selectedJob = jobs.find((u) => u._id === id);

        let selectedCompany;
        if (selectedJob) {
          selectedCompany = companies?.find(
            (c) => c._id === selectedJob.company,
          );

          if (!selectedCompany) {
            const url = `${config.api}/${DataType.COMPANY}/${selectedJob.company}?company=${selectedJob.company}`;
            const comp = await props.helpers.crud.run(
              CRUDType.READ_ONE,
              DataType.COMPANY,
              url,
            );
            const cloneComps = cloneDeep(companies);

            if (cloneComps) {
              cloneComps.push(comp.data);
              setCompanies(cloneComps);

              // Build Options
              setCompanyOptions(
                DataHelper.buildCompanySelectOptions(cloneComps),
              );
            }
          }
        }
      }
    }
    await actionHelper.handleAction(
      DataType.JOB,
      (state) => setSelectedJob(state),
      action,
      id,
      data,
    );
  };

  const handleSearch = useCallback(
    async (options: any[], searchObj: any, dataType: DataType) => {
      if (
        searchObj &&
        searchObj.searchQuery !== searchedValue &&
        searchObj.searchQuery.length > 2
      ) {
        const result = await props.helpers.crud
          .process(searchObj.searchQuery)
          .run(CRUDType.SEARCH, dataType, undefined);

        let prevOptions;

        switch (dataType) {
          case DataType.COMPANY:
            prevOptions = companies;
            break;
          case DataType.USER:
            prevOptions = users;
            break;
          case DataType.CUSTOMER:
            prevOptions = customers;
            break;
        }

        // Add customers to dataSet
        const updatedRes = [...prevOptions];
        result.data.forEach((cus) => {
          const exists = prevOptions.find((c) => c._id === cus._id);
          if (!exists) {
            updatedRes.push(cus);
          }
        });

        setSearchedValue(searchObj.searchQuery);

        let opts;
        switch (dataType) {
          case DataType.COMPANY:
            setCompanies(updatedRes);
            opts = DataHelper.buildCompanySelectOptions(result.data);
            break;
          case DataType.USER:
            setUsers(updatedRes);
            opts = DataHelper.buildUserSelectOptions(result.data, [
              {
                title: 'Position',
                data: 'position',
              },
            ]);
            break;
          case DataType.CUSTOMER:
            setCustomers(updatedRes);
            opts = DataHelper.buildUserSelectOptions(
              updatedRes,
              [
                {title: 'Company', data: 'companyName'},
                {title: 'Account Number', data: 'account', toUpper: true},
                {title: 'Postcode(s)', data: 'postalCode', toUpper: true},
              ],
              true,
            );
            break;
        }

        const searchOpts = cloneDeep(searchOptions);
        searchOpts[dataType] = opts;
        setSearchOptions(searchOpts);
      }
    },
    [
      searchedValue,
      props.helpers.crud,
      searchOptions,
      companies,
      users,
      customers,
    ],
  );

  const createStageRangeFilters = (filters: any = {}) => {
    const stageQuery: any = {$and: []};

    if (filters['stage.start']) {
      const split = filters['stage.start'].split(' - ');
      const startQuery = {
        $gte: split[0],
        $lte: split[1],
      };
      delete filters['stage.start'];

      const stageStartQuery: any = [];
      JobEnums.JobStages.forEach((stage) => {
        stageStartQuery.push({[`stages.${stage}.start`]: startQuery});
      });
      stageQuery['$and'].push({$or: stageStartQuery});
    }

    if (filters['stage.due']) {
      const split = filters['stage.due'].split(' - ');
      const dueQuery = {
        $gte: split[0],
        $lte: split[1],
      };
      delete filters['stage.due'];

      const stageDueQuery: any = [];
      JobEnums.JobStages.forEach((stage) => {
        stageDueQuery.push({[`stages.${stage}.due`]: dueQuery});
      });
      stageQuery['$and'].push({$or: stageDueQuery});
    }

    if (filters['stage.status']) {
      if (filters.stage) {
        stageQuery['$and'].push({
          [`stages.${filters.stage}.status`]: filters['stage.status'],
        });
      } else if (filters['stage.assignedTo']) {
        const stageStatusQuery: any = [];
        JobEnums.JobStages.forEach((stage) => {
          stageStatusQuery.push({
            [`stages.${stage}.status`]: filters['stage.status'],
            [`stages.${stage}.assignedTo`]: filters['stage.assignedTo'],
          });
        });
        stageQuery['$and'].push({$or: stageStatusQuery});
      } else {
        const stageStatusQuery: any = [];
        JobEnums.JobStages.forEach((stage) => {
          stageStatusQuery.push({
            [`stages.${stage}.status`]: filters['stage.status'],
          });
        });

        stageQuery['$and'].push({$or: stageStatusQuery});
      }
      delete filters['stage.status'];
    }

    if (filters['stage.assignedTo'] && !filters['stage.status']) {
      const stageAssignedToQuery: any = [];

      if (filters['stage']) {
        stageAssignedToQuery.push({
          [`stages.${filters['stage']}.assignedTo`]: filters[
            'stage.assignedTo'
          ],
        });
      } else {
        JobEnums.JobStages.forEach((stage) => {
          stageAssignedToQuery.push({
            [`stages.${stage}.assignedTo`]: filters['stage.assignedTo'],
          });
        });
      }

      delete filters['stage.assignedTo'];

      stageQuery['$and'].push({$or: stageAssignedToQuery});
    }

    if (stageQuery['$and'].length) {
      filters['$and'] = JSON.stringify(stageQuery['$and']);
    }

    return filters;
  };

  const filterStageStatuses = (stage: JobStage) => {
    switch (stage) {
      case JobStage.POS:
        return JobEnums.PosStageOptions;
      case JobStage.PRE:
        return JobEnums.PreStageOptions;
      case JobStage.ADMIN:
        return JobEnums.AdminStageOptions;
      case JobStage.POST:
        return JobEnums.PostStageOptions;
      case JobStage.SERVICE:
        return JobEnums.ServiceStageOptions;
      default:
        return JobEnums.JobStatusOptions;
    }
  };

  let filterSections: Section[] = [
    {
      title: '',
      fields: [
        {
          hidden: props.loggedInUser.role !== UserRole.SUPER_ADMIN,
          title: 'Company',
          dataField: 'company',
          dataSet: searchOptions[DataType.COMPANY] || companyOptions,
          dataType: DataType.COMPANY,
          type: DataFormType.SEARCH_SELECT_API,
          placeholder: 'Type to search',
        },
        {
          title: 'Job ID Prefix',
          dataField: 'jobPrefix',
          dataSet: props.loggedInCompany.jobCodes.reduce(
            (acc: DropdownItemProps[], jc, i) => {
              if (!acc.find((v) => v.value === jc.name)) {
                return [
                  ...acc,
                  {
                    key: `prefix-${jc.name}-${i}`,
                    value: jc.name,
                    text: jc.name,
                  },
                ];
              }
              return acc;
            },
            [],
          ),
          type: DataFormType.SELECT,
        },
        {
          title: 'Job ID',
          dataField: 'jobId',
          type: DataFormType.TEXT,
        },
        {
          title: 'Job Type',
          dataField: 'type',
          type: DataFormType.TEXT,
        },
        {
          title: 'Stage',
          dataField: 'stage',
          dataSet: JobEnums.JobStageOptions(props.loggedInCompany.stageTitles),
          type: DataFormType.SELECT,
        },
        {
          title: 'Stage Status',
          dataField: 'stage.status',
          dataSet: filterStageStatuses(filters && filters.stage),
          type: DataFormType.SELECT,
        },
        {
          disabled: props.loggedInUser.role === UserRole.SALES ? 'true' : null,
          title: 'Assigned To',
          dataField: 'stage.assignedTo',
          dataSet:
            props.loggedInUser.role !== UserRole.SALES
              ? searchOptions[DataType.USER] || userOptions
              : [
                  {
                    key: props.loggedInUser._id,
                    value: props.loggedInUser._id,
                    text: `${props.loggedInUser.firstName} ${props.loggedInUser.lastName} - ${props.loggedInUser.position}`,
                  },
                ],
          dataType: DataType.USER,
          type:
            props.loggedInUser.role !== UserRole.SALES
              ? DataFormType.SEARCH_SELECT_API
              : DataFormType.SELECT,
          placeholder: 'Type to search',
        },
        {
          title: 'Customer/Company',
          dataField: 'customer',
          dataSet: searchOptions[DataType.CUSTOMER] || customerOptions,
          dataType: DataType.CUSTOMER,
          type: DataFormType.SEARCH_SELECT_API,
          placeholder: 'Type to search',
        },
        {
          title: 'Lead Source',
          dataField: 'leadSource',
          dataSet: props.loggedInCompany.leadSources?.reduce(
            (acc: DropdownItemProps[], ls, i) => {
              if (!acc.find((v) => v.value === ls.name)) {
                return [
                  ...acc,
                  {
                    key: `lead-source-${ls.name}-${i}`,
                    value: ls.name,
                    text: ls.name,
                  },
                ];
              }
              return acc;
            },
            [],
          ),
          type:
            props.loggedInUser.role === UserRole.SUPER_ADMIN
              ? DataFormType.TEXT
              : DataFormType.SELECT,
        },
      ],
    },
  ];

  if (!calendarDates || !calendarDates.start) {
    filterSections[0].fields = filterSections[0].fields.concat([
      {
        title: 'Start Date',
        dataField: `stage.start`,
        type: DataFormType.DATE_RANGE,
      },
      {
        title: 'Due Date',
        dataField: `stage.due`,
        type: DataFormType.DATE_RANGE,
      },
      {
        title: 'Created Date',
        dataField: `created`,
        type: DataFormType.DATE_RANGE,
      },
    ]);
  }

  if (!props.loggedInCompany._id) {
    return null;
  }

  return (
    <>
      {!hideFilter && (
        <aside>
          <div className={'box'}>
            <DataFilter
              sections={filterSections}
              data={
                props.loggedInUser.role === UserRole.SALES
                  ? {...filters, 'stage.assignedTo': props.loggedInUser._id}
                  : filters
              }
              setData={setFilters}
              dataType={DataType.JOB}
              primaryAction={() => {
                // Load jobs
                setPage(1);
                setLoadedJobs(false);
                setLoadingJobs(false);
                loadTotals();
              }}
              searchAction={handleSearch}
            />
          </div>
        </aside>
      )}

      <main style={{gridColumn: hideFilter ? '1/-1' : ''}}>
        {props.loggedInUser.role === UserRole.SUPER_ADMIN && (
          <FileImport
            dataType={DataType.JOB}
            loggedInUser={props.loggedInUser}
            crudAction={props.crudAction}
            setOpenState={setImportOpen}
            openState={importOpen}
          />
        )}

        {props.isHome && <Welcome loggedInUser={props.loggedInUser} />}

        <div
          className={`box ${props.isHome && 'HomeTableHeight'} ${
            props.loggedInUser.role === UserRole.SUPER_ADMIN
              ? importOpen
                ? 'UploadTableHeightOpen'
                : 'UploadTableHeightClosed'
              : ''
          }`}
        >
          <DataTable
            loggedInUser={props.loggedInUser}
            loggedInCompany={props.loggedInCompany}
            hideFilter={hideFilter}
            setHideFilter={setHideFilter}
            title="Jobs"
            titleIcon={<FontAwesomeIcon icon={faTasks} />}
            description={
              <div>
                <p>You can add, update and delete 'Jobs' in this section.</p>
                <p>
                  Once added, your list of open or closed jobs, along with each
                  stage of the job's status can be found here.
                </p>
              </div>
            }
            className={'StyledDataTable'}
            export={true}
            metrics={metrics}
            calendar={true}
            calendarData={calendarData || []}
            calendarAction={(start, end) => {
              setCalendarDates({
                start,
                end,
              });
              setLoadedJobs(false);
              setLoadingJobs(false);
            }}
            data={jobs || []}
            dataType={DataType.JOB}
            primaryAction={
              props.loggedInUser.role !== UserRole.SALES
                ? CRUDType.CREATE
                : null
            }
            clickAction={CRUDType.UPDATE}
            handleAction={(action: CRUDType, id: string, data: any) =>
              handleAction(action, id, data)
            }
            apiSortOrder={sortOrder}
            paginationAction={(page, resetDates = false, activeSortOrder?) => {
              if (resetDates) {
                setCalendarDates({start: undefined, end: undefined});
              }

              if (activeSortOrder) {
                setSortOrder(activeSortOrder);
              }

              setPage(page);
              setLoadedJobs(false);
              setLoadingJobs(false);
            }}
            additional={{
              customers,
              users,
              filters: formatFilters(),
              loggedInUser: props.loggedInUser,
            }}
            collectionTotal={jobTotal}
            width={7}
            cols={[
              {
                title: 'Job',
                dataField: 'jobId',
                type: DataTableType.JOB_PREFIX_ID,
                mobileDisplay: true,
              },
              {
                title: 'Created',
                dataField: 'created',
                type: DataTableType.DATE_TIME,
                mobileDisplay: false,
              },
              {
                title: 'Customer',
                dataField: 'customer',
                dataProcess: {
                  action: DataHelper.getValueFromData,
                  data: customers,
                  field: ValueTemplate.CONTACT,
                  dataField: 'contact.installation',
                },
                type: DataTableType.STRING,
                mobileDisplay: true,
              },
              {
                title: 'Company',
                dataField: 'customer',
                dataProcess: {
                  action: DataHelper.getValueFromData,
                  data: customers,
                  field: 'companyName',
                },
                type: DataTableType.STRING,
                mobileDisplay: false,
              },
              {
                title: `${jobStageMap(
                  JobStage.POS,
                  props.loggedInCompany.stageTitles,
                )} Status`,
                dataField: 'stages.pos.status',
                type: DataTableType.STRING,
                mobileDisplay: true,
                className: (value, item) =>
                  `${
                    item.stage !== JobStage.POS ? 'InactiveCell ' : ''
                  } ${DataHelper.statusColourClass(value)}`,
              },
              {
                title: `${jobStageMap(
                  JobStage.PRE,
                  props.loggedInCompany.stageTitles,
                )} Status`,
                dataField: 'stages.pre.status',
                type: DataTableType.STRING,
                mobileDisplay: true,
                className: (value, item) =>
                  `${
                    item.stage !== JobStage.PRE ? 'InactiveCell ' : ''
                  } ${DataHelper.statusColourClass(value)}`,
              },
              {
                title: `${jobStageMap(
                  JobStage.ADMIN,
                  props.loggedInCompany.stageTitles,
                )} Status`,
                dataField: 'stages.admin.status',
                type: DataTableType.STRING,
                mobileDisplay: true,
                className: (value, item) =>
                  `${
                    item.stage !== JobStage.ADMIN ? 'InactiveCell ' : ''
                  } ${DataHelper.statusColourClass(value)}`,
              },
              {
                title: `${jobStageMap(
                  JobStage.POST,
                  props.loggedInCompany.stageTitles,
                )} Status`,
                dataField: 'stages.post.status',
                type: DataTableType.STRING,
                mobileDisplay: true,
                className: (value, item) =>
                  `${
                    item.stage !== JobStage.POST ? 'InactiveCell ' : ''
                  } ${DataHelper.statusColourClass(value)}`,
              },
              {
                title: `${jobStageMap(
                  JobStage.SERVICE,
                  props.loggedInCompany.stageTitles,
                )} Status`,
                dataField: 'stages.service.status',
                type: DataTableType.STRING,
                mobileDisplay: true,
                className: (value, item) =>
                  `${
                    item.stage !== JobStage.SERVICE ? 'InactiveCell ' : ''
                  } ${DataHelper.statusColourClass(value)}`,
              },
            ]}
          />
        </div>

        {props.showDataForm.status && (
          <JobModal
            loggedInUser={props.loggedInUser}
            loggedInCompany={props.loggedInCompany}
            crudAction={props.crudAction}
            showDataForm={props.showDataForm}
            actionHelper={actionHelper}
            setDataForm={props.setDataForm}
            resetDataType={() => setActiveDataType(DataType.JOB)}
            selected={selectedJob}
            setSelected={setSelectedJob}
            companies={companies}
            companyOptions={companyOptions}
            users={users}
            userOptions={userOptions}
            customers={customers}
            customerOptions={customerOptions}
            searchAction={handleSearch}
            searchOptions={searchOptions}
            setSearchOptions={setSearchOptions}
            setModalData={props.helpers.modal.setState(selectedJob)}
            setPromptModal={(modalType, status, title, body, action) =>
              props.helpers.modal.showModal(
                modalType,
                status,
                title,
                body,
                action,
              )
            }
          />
        )}
      </main>
    </>
  );
};

export default Jobs;
