import ObjectId from 'bson-objectid';
import {cloneDeep, isEqual} from 'lodash';
import React, {useEffect, useState} from 'react';
import {Button, Icon, Modal} from 'semantic-ui-react';
import config from '../../auth_config.json';
import {DataType} from '../../const/crud';
import {Company, CompanyThemeItemType} from '../../models/Company';
import {MediaLink, MediaType} from '../../models/Media';
import {User} from '../../models/User';
import {useAuth0} from '../../react-auth0-spa';
import {ModalHelper, ModalParams} from '../../utils/modalHelper';
import {CRUDType, DataActionType, Field} from './DataConst';
import {validateField} from './DataValidation';
import FormFields from './FormFields';

export interface PromptModalProps {
  modalHelper: ModalHelper;
  showModal: ModalParams;
  crudAction: any;
  loggedInUser: User;
  loggedInCompany: Company;
  dataControl: any;
  setLoading: any;
}

let initialModalData: any = {
  checklist: {},
  note: '',
  location: '',
  title: '',
  body: '',
  media: [],
  attachment: [],
  access: undefined,
  contact: {},
  pricing: {},
  costGroup: {},
  cost: {},
};

export const PromptModal: React.FunctionComponent<PromptModalProps> = (
  props: PromptModalProps,
) => {
  const action = props.showModal.action && props.showModal.action.action.type;
  const state = props.modalHelper.getState();

  const [modalData, setModalData] = useState(initialModalData);
  const {logout} = useAuth0();

  useEffect(() => {
    if (
      props.showModal.data &&
      action &&
      action === DataActionType.EDIT_CONTACT &&
      isEqual(modalData, initialModalData)
    ) {
      const cloneInitial = cloneDeep(initialModalData);
      cloneInitial.contact = props.showModal.data;
      cloneInitial['contact.title'] = props.showModal.data.title;
      cloneInitial['contact.firstName'] = props.showModal.data.firstName;
      cloneInitial['contact.lastName'] = props.showModal.data.lastName;
      cloneInitial['contact.phone'] = props.showModal.data.phone;
      cloneInitial['contact.email'] = props.showModal.data.email;
      cloneInitial['contact.address.address1'] =
        props.showModal.data.address.address1;
      cloneInitial['contact.address.address2'] =
        props.showModal.data.address.address2;
      cloneInitial['contact.address.address3'] =
        props.showModal.data.address.address3;
      cloneInitial['contact.address.postalCode'] =
        props.showModal.data.address.postalCode;
      cloneInitial['contact.primaryContact'] =
        props.showModal.data.primaryContact;

      setModalData(cloneInitial);
    }

    if (
      props.showModal.data &&
      action &&
      action === DataActionType.EDIT_CHECKLIST &&
      isEqual(modalData, initialModalData)
    ) {
      const cloneInitial = cloneDeep(initialModalData);
      cloneInitial.checklist = props.showModal.data;
      cloneInitial['checklist.text'] = props.showModal.data.text;
      cloneInitial['checklist.status'] = props.showModal.data.status;

      setModalData(cloneInitial);
    }

    if (
      props.showModal.data &&
      action &&
      action === DataActionType.EDIT_CHECKLIST_TEMPLATE &&
      isEqual(modalData, initialModalData)
    ) {
      const cloneInitial = cloneDeep(initialModalData);
      cloneInitial.checklist = props.showModal.data;
      cloneInitial['checklist.name'] = props.showModal.data.name;
      cloneInitial['checklist.checklistType'] =
        props.showModal.data.checklistType;
      cloneInitial['checklist.tasks'] = props.showModal.data.tasks;

      setModalData(cloneInitial);
    }

    if (
      props.showModal.data &&
      action &&
      action === DataActionType.EDIT_PAYMENT &&
      isEqual(modalData, initialModalData)
    ) {
      const cloneInitial = cloneDeep(initialModalData);
      cloneInitial.pricing = props.showModal.data;
      cloneInitial['pricing.status'] = props.showModal.data.status;
      cloneInitial['pricing.paymentNumber'] =
        props.showModal.data.paymentNumber;
      cloneInitial['pricing.description'] = props.showModal.data.description;
      cloneInitial['pricing.amount'] = props.showModal.data.amount;
      cloneInitial['pricing.invoiceNumber'] =
        props.showModal.data.invoiceNumber;

      setModalData(cloneInitial);
    }

    if (
      props.showModal.data &&
      action &&
      action === DataActionType.EDIT_COST_GROUP &&
      isEqual(modalData, initialModalData)
    ) {
      const cloneInitial = cloneDeep(initialModalData);
      cloneInitial.costGroup = props.showModal.data;
      cloneInitial['costGroup.name'] = props.showModal.data.name;
      cloneInitial['costGroup.costs'] = props.showModal.data.costs;

      setModalData(cloneInitial);
    }

    if (
      props.showModal.data &&
      action &&
      action === DataActionType.EDIT_COST &&
      isEqual(modalData, initialModalData)
    ) {
      const cloneInitial = cloneDeep(initialModalData);
      cloneInitial.cost = props.showModal.data;
      cloneInitial['cost.name'] = props.showModal.data.name;
      cloneInitial['cost.amount'] = props.showModal.data.amount;
      cloneInitial['cost.stage'] = props.showModal.data.stage;
      cloneInitial['cost.type'] = props.showModal.data.type;
      cloneInitial['cost.description'] = props.showModal.data.description;
      cloneInitial['cost.company'] = props.showModal.data.company;
      cloneInitial['cost.invoiceNumber'] = props.showModal.data.invoiceNumber;

      setModalData(cloneInitial);
    }

    if (
      props.showModal.data &&
      action &&
      action === DataActionType.EDIT_JOB_CODE &&
      isEqual(modalData, initialModalData)
    ) {
      const cloneInitial = cloneDeep(initialModalData);
      cloneInitial.jobCode = props.showModal.data;
      cloneInitial['jobCode.name'] = props.showModal.data.name;
      cloneInitial['jobCode.startNumber'] = props.showModal.data.startNumber;

      setModalData(cloneInitial);
    }

    if (
      props.showModal.data &&
      action &&
      action === DataActionType.EDIT_LEAD_SOURCE &&
      isEqual(modalData, initialModalData)
    ) {
      const cloneInitial = cloneDeep(initialModalData);
      cloneInitial.leadSource = props.showModal.data;
      cloneInitial['leadSource.name'] = props.showModal.data.name;

      setModalData(cloneInitial);
    }

    if (
      props.showModal.data &&
      action &&
      action === DataActionType.EDIT_STAFF_AVAILABILITY &&
      isEqual(modalData, initialModalData)
    ) {
      const cloneInitial = cloneDeep(initialModalData);
      cloneInitial.staffAvailability = props.showModal.data;
      cloneInitial['staffAvailability.userId'] = props.showModal.data.userId;
      cloneInitial['staffAvailability.startDate'] =
        props.showModal.data.startDate;
      cloneInitial['staffAvailability.endDate'] = props.showModal.data.endDate;
      cloneInitial['staffAvailability.description'] =
        props.showModal.data.description;

      setModalData(cloneInitial);
    }

    if (
      props.showModal.data &&
      action &&
      action === DataActionType.EDIT_CUSTOM_FIELD &&
      isEqual(modalData, initialModalData)
    ) {
      const cloneInitial = cloneDeep(initialModalData);
      cloneInitial.customFields = props.showModal.data;
      cloneInitial['customField.name'] = props.showModal.data.name;
      cloneInitial['customField.type'] = props.showModal.data.type;
      cloneInitial['customField.selectOptions'] =
        props.showModal.data.selectOptions;
      cloneInitial['customField.isRequired'] = props.showModal.data.isRequired;

      setModalData(cloneInitial);
    }
  }, [action, modalData, props.showModal.data]);

  const formValidated = (): boolean => {
    // Get all fields that are required
    if (props.showModal.fields) {
      const requiredFields: Field[] = props.showModal.fields.filter(
        (field) => field.validation && field.validation.length,
      );

      for (const field of requiredFields) {
        if (field.validation) {
          for (const valid of field.validation) {
            if (!validateField(modalData[field.dataField], valid)) {
              return false;
            }
          }
        }
      }
    }

    return true;
  };
  formValidated();

  return (
    <Modal
      className={'PromptModalContainer'}
      dimmer="inverted"
      open={props.showModal.status}
      onClose={() => {
        if (
          props.showModal.action &&
          props.showModal.action.action &&
          props.showModal.action.action.type === 'link'
        ) {
          window.location.href =
            props.showModal.action && props.showModal.action.action.data;
        }
      }}
    >
      <Modal.Header>{props.showModal.title}</Modal.Header>
      <Modal.Content scrolling>
        <Modal.Description>
          <div
            style={
              props.showModal?.body?.length && props.showModal?.fields
                ? {marginBottom: 20}
                : {}
            }
          >
            {props.showModal.body}
          </div>

          {props.showModal.fields && (
            <FormFields
              modalState={{data: modalData, setter: setModalData}}
              fields={props.showModal.fields}
              action={props.showModal.action}
            />
          )}
        </Modal.Description>
      </Modal.Content>

      {props.showModal.action && (
        <Modal.Actions>
          <Button
            className="ui primary"
            type="submit"
            icon
            disabled={!formValidated()}
            labelPosition={'left'}
            onClick={async () => {
              const action =
                props.showModal.action && props.showModal.action.action;
              let data;
              if (action) {
                switch (action.type) {
                  case 'close':
                    props.modalHelper.hideModal(props.showModal.showDataForm);
                    break;
                  case 'link':
                    window.location.href =
                      props.showModal.action &&
                      props.showModal.action.action.data;
                    break;
                  case 'logout':
                    logout({returnTo: config.returnTo});
                    break;
                  case CRUDType.DELETE:
                    await props.crudAction(
                      CRUDType.DELETE,
                      action.dataType,
                      action.documentId,
                    );

                    if (action.callback) {
                      action.callback();
                    }

                    break;

                  case CRUDType.CANCEL:
                    if (action.callback) {
                      action.callback();
                      props.modalHelper.hideModal();
                    }

                    break;

                  case DataActionType.DELETE_NOTE:
                    // Get Data
                    data = cloneDeep(state);

                    // Delete note
                    let notes =
                      action.data && action.data.stage
                        ? data.stages[action.data.stage].notes
                        : data.notes;

                    notes = notes.filter((note) => note._id !== action.dataId);

                    if (action.data && action.data.stage) {
                      data.stages[action.data.stage].notes = notes;
                    } else {
                      data.notes = notes;
                    }

                    action.dataSetter(data);
                    props.modalHelper.hideModal();
                    break;

                  case DataActionType.CREATE_NOTE:
                    // Get data
                    data = cloneDeep(state);

                    if (action?.data?.stageData) {
                      data.stages = action.data.stageData;
                    }

                    // Add note
                    const noteLocation =
                      (props.showModal.action &&
                        props.showModal.action.action &&
                        props.showModal.action.action.data &&
                        props.showModal.action.action.data.location) ||
                      '-';
                    const path =
                      action.data && action.data.stage
                        ? data.stages[action.data.stage].notes
                        : data.notes;
                    path.push({
                      _id: new ObjectId().toHexString(),
                      created: new Date().toISOString(),
                      createdBy: props.loggedInUser._id,
                      text: modalData.note,
                      location: noteLocation,
                    });

                    action.dataSetter(data);
                    setModalData(initialModalData);
                    props.modalHelper.hideModal();
                    break;

                  case DataActionType.CREATE_MEDIA:
                    props.setLoading(true);

                    // Upload media and get mediaLink
                    const mediaLocation =
                      (props.showModal.action &&
                        props.showModal.action.action &&
                        props.showModal.action.action.data &&
                        props.showModal.action.action.data.location) ||
                      '-';
                    const newMedia: MediaLink[] = [];
                    for (let i = 0; i < modalData.media.length; i++) {
                      const mime = modalData.media[i].type.split('/');
                      const mediaType =
                        mime[0] === 'image' ? MediaType.IMAGE : MediaType.VIDEO;

                      let title = '';
                      if (modalData.title) {
                        if (modalData.media.length > 1 && i > 0) {
                          title = `${modalData.title} (${i})`;
                        } else {
                          title = modalData.title;
                        }
                      }

                      const result = await props.crudAction(
                        CRUDType.UPLOAD_MEDIA,
                        DataType.MEDIA,
                        {
                          body: {
                            created: new Date(),
                            createdBy: props.loggedInUser._id,
                            location: mediaLocation,
                            title,
                          },
                          media: modalData.media[i],
                        },
                        false,
                      );

                      const mediaObj: MediaLink = {
                        created: new Date().toISOString(),
                        createdBy: props.loggedInUser._id,
                        mediaId: result.data._id,
                        mediaType,
                        location: mediaLocation,
                        title,
                      };
                      newMedia.push(mediaObj);
                    }
                    data = cloneDeep(state);

                    // Add media to stage
                    data.stages[action.data.stage].media = data.stages[
                      action.data.stage
                    ].media = data.stages[
                      action.data.stage
                    ].media = data.stages[action.data.stage].media.concat(
                      newMedia,
                    );

                    action.dataSetter(data);
                    setModalData(initialModalData);
                    props.modalHelper.hideModal();

                    props.setLoading(false);
                    break;

                  case DataActionType.DELETE_MEDIA:
                    // Delete media from server
                    await props.crudAction(
                      CRUDType.DELETE_MEDIA,
                      DataType.MEDIA,
                      action.dataId,
                      false,
                    );

                    // Get Data
                    data = cloneDeep(state);
                    // Delete media
                    data.stages[action.data.stage].media = data.stages[
                      action.data.stage
                    ].media.filter((med) => med.mediaId !== action.dataId);

                    action.dataSetter(data);
                    props.modalHelper.hideModal();

                    break;

                  case DataActionType.CREATE_ATTACHMENT:
                    props.setLoading(true);

                    // Upload attachment and get mediaLink
                    const newAttachment: MediaLink[] = [];
                    for (let i = 0; i < modalData.attachment.length; i++) {
                      const result = await props.crudAction(
                        CRUDType.UPLOAD_MEDIA,
                        DataType.ATTACHMENT,
                        {
                          body: {
                            created: new Date(),
                            createdBy: props.loggedInUser._id,
                            title: modalData.title
                              ? `${new ObjectId()}---${modalData.title}${
                                  modalData.attachment.length > 1 && i > 0
                                    ? ' (' + i + ')'
                                    : ''
                                }`
                              : `${new ObjectId()}---${
                                  modalData.attachment[i].name
                                }`,
                          },
                          media: modalData.attachment[i],
                        },
                        false,
                      );

                      const access =
                        props.showModal &&
                        props.showModal.action &&
                        props.showModal.action.action.data &&
                        props.showModal.action.action.data.access;

                      const mediaObj: MediaLink = {
                        created: new Date().toISOString(),
                        createdBy: props.loggedInUser._id,
                        mediaId: result.data._id,
                        mediaType: MediaType.FILE,
                        title: modalData.title
                          ? `${modalData.title}${
                              modalData.attachment.length > 1 && i > 0
                                ? ' (' + i + ')'
                                : ''
                            }`
                          : `${modalData.attachment[i].name}`,
                        access,
                      };
                      newAttachment.push(mediaObj);
                    }

                    data = cloneDeep(state);

                    // Add attachment to job
                    data.attachments = data.attachments.concat(newAttachment);

                    action.dataSetter(data);
                    setModalData(initialModalData);
                    props.modalHelper.hideModal();
                    break;

                  case DataActionType.DELETE_ATTACHMENT:
                    // Delete attachment from server
                    await props.crudAction(
                      CRUDType.DELETE_MEDIA,
                      DataType.ATTACHMENT,
                      action.dataId,
                      false,
                    );

                    // Get Data
                    data = cloneDeep(state);
                    // Delete media
                    data.attachments = data.attachments.filter(
                      (item) => item.mediaId !== action.dataId,
                    );
                    action.dataSetter(data);
                    props.modalHelper.hideModal();

                    props.setLoading(false);
                    break;

                  case DataActionType.DELETE_LOCATION:
                    // Get Data
                    data = cloneDeep(state);
                    // Delete location
                    data.locations = data.locations.filter(
                      (item) => item._id !== action.dataId,
                    );
                    action.dataSetter(data);
                    props.modalHelper.hideModal();
                    break;

                  case DataActionType.CREATE_LOCATION:
                    // Get data
                    data = cloneDeep(state);
                    // Add location
                    let locationPath = data.locations;
                    locationPath.push({
                      _id: new ObjectId().toHexString(),
                      created: new Date().toISOString(),
                      createdBy: props.loggedInUser._id,
                      location: modalData.location,
                    });

                    action.dataSetter(data);
                    setModalData(initialModalData);
                    props.modalHelper.hideModal();
                    break;

                  case DataActionType.DELETE_CONTACT:
                    // Get Data
                    data = cloneDeep(state);
                    // Delete contact
                    data.contact = data.contact.filter(
                      (item) => item._id !== action.dataId,
                    );
                    action.dataSetter(data);
                    props.modalHelper.hideModal();
                    break;

                  case DataActionType.CREATE_CONTACT:
                    // Get data
                    data = cloneDeep(state);

                    // Remove primary mark if being set here
                    if (modalData['contact.primaryContact'] === 'true') {
                      data.contact = data.contact.map((con) => {
                        con.primaryContact = false;
                        return con;
                      });
                    }

                    data.contact.push({
                      _id: new ObjectId().toHexString(),
                      primaryContact:
                        modalData['contact.primaryContact'] === 'true',
                      created: new Date().toISOString(),
                      createdBy: props.loggedInUser._id,
                      title: modalData['contact.title'],
                      firstName: modalData['contact.firstName'],
                      lastName: modalData['contact.lastName'],
                      phone: modalData['contact.phone'],
                      address: {
                        address1: modalData['contact.address.address1'],
                        address2: modalData['contact.address.address2'],
                        address3: modalData['contact.address.address3'],
                        postalCode: modalData['contact.address.postalCode'],
                      },
                      email: modalData['contact.email'],
                    });

                    action.dataSetter(data);
                    setModalData(initialModalData);
                    props.modalHelper.hideModal();
                    break;

                  case DataActionType.EDIT_CONTACT:
                    // Get data
                    data = cloneDeep(state);

                    // Remove primary mark if being set here
                    if (modalData['contact.primaryContact'] === 'true') {
                      data.contact = data.contact.map((con) => {
                        con.primaryContact = false;
                        return con;
                      });
                    }

                    for (const contact of data.contact) {
                      if (contact._id === modalData.contact._id) {
                        contact.primaryContact =
                          modalData['contact.primaryContact'] === 'true';
                        contact.title = modalData['contact.title'];
                        contact.firstName = modalData['contact.firstName'];
                        contact.lastName = modalData['contact.lastName'];
                        contact.phone = modalData['contact.phone'];
                        contact.address = {
                          address1: modalData['contact.address.address1'],
                          address2: modalData['contact.address.address2'],
                          address3: modalData['contact.address.address3'],
                          postalCode: modalData['contact.address.postalCode'],
                        };
                        contact.email = modalData['contact.email'];
                      }
                    }

                    action.dataSetter(data);
                    setModalData(initialModalData);
                    props.modalHelper.hideModal();
                    break;

                  case DataActionType.DELETE_CHECKLIST:
                    // Get Data
                    data = cloneDeep(state);
                    // Delete checklist item
                    data.stages[action.data.stage].checklist = data.stages[
                      action.data.stage
                    ].checklist.filter((i) => i._id !== action.dataId);
                    action.dataSetter(data);
                    props.modalHelper.hideModal();
                    break;

                  case DataActionType.CREATE_CHECKLIST:
                    // Get data
                    data = cloneDeep(state);

                    // Add checklist
                    data.stages[action.data.stage].checklist =
                      data.stages[action.data.stage].checklist || [];

                    let checklistPath =
                      data.stages[action.data.stage].checklist;

                    checklistPath.push({
                      _id: new ObjectId().toHexString(),
                      created: new Date().toISOString(),
                      createdBy: props.loggedInUser._id,
                      text: modalData['checklist.text'],
                      status: modalData['checklist.status'],
                    });

                    action.dataSetter(data);
                    setModalData(initialModalData);
                    props.modalHelper.hideModal();
                    break;

                  case DataActionType.EDIT_CHECKLIST:
                    // Get data
                    data = cloneDeep(state);
                    for (const item of data.stages[action.data.data.stage]
                      .checklist) {
                      if (item._id === modalData.checklist._id) {
                        item.text = modalData['checklist.text'];

                        if (
                          item.status !== 'completed' &&
                          modalData['checklist.status'] === 'completed'
                        ) {
                          item.completed = new Date().toISOString();
                          item.completedBy = props.loggedInUser._id;
                        } else if (
                          modalData['checklist.status'] === 'not-completed'
                        ) {
                          item.completed = null;
                          item.completedBy = null;
                        }

                        item.status = modalData['checklist.status'];
                      }
                    }

                    action.dataSetter(data);
                    setModalData(initialModalData);
                    props.modalHelper.hideModal();
                    break;

                  case DataActionType.CREATE_CHECKLIST_TEMPLATE:
                    // Get data
                    data = cloneDeep(state);

                    // Add checklist template
                    data.checklists.push({
                      _id: new ObjectId().toHexString(),
                      created: new Date().toISOString(),
                      createdBy: props.loggedInUser._id,
                      name: modalData['checklist.name'],
                      checklistType: modalData['checklist.checklistType'],
                      tasks: modalData['checklist.tasks'],
                      count: modalData['checklist.tasks']?.length || 0,
                    });

                    action.dataSetter(data);
                    setModalData(initialModalData);
                    props.modalHelper.hideModal();
                    break;

                  case DataActionType.DELETE_CHECKLIST_TEMPLATE:
                    // Get Data
                    data = cloneDeep(state);
                    // Delete checklist
                    data.checklists = data.checklists.filter(
                      (i) => i._id !== action.dataId,
                    );
                    action.dataSetter(data);
                    props.modalHelper.hideModal();
                    break;

                  case DataActionType.EDIT_CHECKLIST_TEMPLATE:
                    // Get data
                    data = cloneDeep(state);

                    for (const checklist of data.checklists) {
                      if (checklist._id === modalData.checklist._id) {
                        checklist.name = modalData['checklist.name'];
                        checklist.checklistType =
                          modalData['checklist.checklistType'];
                        checklist.tasks = modalData['checklist.tasks'];
                        checklist.count = modalData['checklist.tasks'].length;
                      }
                    }

                    action.dataSetter(data);
                    setModalData(initialModalData);
                    props.modalHelper.hideModal();
                    break;

                  case DataActionType.IMPORT_CHECKLIST_TEMPLATE:
                    // Get data
                    data = cloneDeep(state);

                    const importing = action.data.tasks;

                    // Import checklist
                    data.stages[action.data.stage].checklist = (
                      data.stages[action.data.stage].checklist || []
                    ).concat(importing);

                    action.dataSetter(data);
                    setModalData(initialModalData);
                    props.modalHelper.hideModal();
                    break;

                  case DataActionType.IMPORT_XERO_CUSTOMER:
                    // Get data
                    data = cloneDeep(state);

                    const xContact = action.data.contact;

                    // Import contact
                    data.contact.push(xContact);

                    action.dataSetter(data);
                    setModalData(initialModalData);
                    props.modalHelper.hideModal();
                    break;

                  case DataActionType.IMPORT_QUICKBOOKS_CUSTOMER:
                    // Get data
                    data = cloneDeep(state);

                    const qbCompany = action.data.companyName;
                    const qbContact = action.data.contact;

                    if (qbCompany) {
                      // Add company
                      data.companyName = qbCompany;
                    }

                    if (qbContact) {
                      // Import contact
                      data.contact.push(qbContact);
                    }

                    action.dataSetter(data);
                    setModalData(initialModalData);
                    props.modalHelper.hideModal();
                    break;

                  case DataActionType.DELETE_PAYMENT:
                    // Get Data
                    data = cloneDeep(state);
                    // Delete payment
                    data.pricing = data.pricing.filter(
                      (item) => item._id !== action.dataId,
                    );
                    action.dataSetter(data);
                    props.modalHelper.hideModal();
                    break;

                  case DataActionType.CREATE_PAYMENT:
                    // Get data
                    data = cloneDeep(state);
                    // Add payment
                    let paymentPath = data.pricing;
                    paymentPath.push({
                      _id: new ObjectId().toHexString(),
                      created: new Date().toISOString(),
                      createdBy: props.loggedInUser._id,
                      status: modalData['pricing.status'],
                      paymentNumber: modalData['pricing.paymentNumber'],
                      description: modalData['pricing.description'],
                      amount: modalData['pricing.amount'],
                      invoiceNumber: modalData['pricing.invoiceNumber'],
                    });

                    action.dataSetter(data);
                    setModalData(initialModalData);
                    props.modalHelper.hideModal();
                    break;

                  case DataActionType.EDIT_PAYMENT:
                    // Get data
                    data = cloneDeep(state);
                    for (const pricing of data.pricing) {
                      if (pricing._id === modalData.pricing._id) {
                        pricing.status = modalData['pricing.status'];
                        pricing.paymentNumber =
                          modalData['pricing.paymentNumber'];
                        pricing.description = modalData['pricing.description'];
                        pricing.amount = modalData['pricing.amount'];
                        pricing.invoiceNumber =
                          modalData['pricing.invoiceNumber'];
                      }
                    }

                    action.dataSetter(data);
                    setModalData(initialModalData);
                    props.modalHelper.hideModal();
                    break;

                  case DataActionType.CREATE_COST_GROUP:
                    // Get data
                    data = cloneDeep(state);

                    // Add cost group
                    data.costGroups.push({
                      _id: new ObjectId().toHexString(),
                      created: new Date().toISOString(),
                      createdBy: props.loggedInUser._id,
                      name: modalData['costGroup.name'],
                      costs: modalData['costGroup.costs'],
                      count: modalData['costGroup.costs']?.length || 0,
                    });

                    action.dataSetter(data);
                    setModalData(initialModalData);
                    props.modalHelper.hideModal();
                    break;

                  case DataActionType.DELETE_COST_GROUP:
                    // Get Data
                    data = cloneDeep(state);
                    // Delete cost group
                    data.costGroups = data.costGroups.filter(
                      (i) => i._id !== action.dataId,
                    );
                    action.dataSetter(data);
                    props.modalHelper.hideModal();
                    break;

                  case DataActionType.EDIT_COST_GROUP:
                    // Get data
                    data = cloneDeep(state);

                    for (const cg of data.costGroups) {
                      if (cg._id === modalData.costGroup._id) {
                        cg.name = modalData['costGroup.name'];
                        cg.costs = modalData['costGroup.costs'];
                        cg.count = modalData['costGroup.costs'].length;
                      }
                    }

                    action.dataSetter(data);
                    setModalData(initialModalData);
                    props.modalHelper.hideModal();
                    break;

                  case DataActionType.IMPORT_COST_GROUP:
                    // Get data
                    data = cloneDeep(state);

                    const importingCG = action.data.costs;

                    // Import cost group
                    data.costs = data.costs.concat(importingCG);

                    action.dataSetter(data);
                    setModalData(initialModalData);
                    props.modalHelper.hideModal();
                    break;

                  case DataActionType.DELETE_COST:
                    // Get Data
                    data = cloneDeep(state);
                    // Delete cost item
                    data.costs = data.costs.filter(
                      (i) => i._id !== action.dataId,
                    );
                    action.dataSetter(data);
                    props.modalHelper.hideModal();
                    break;

                  case DataActionType.CREATE_COST:
                    // Get data
                    data = cloneDeep(state);

                    // Add cost
                    data.costs = data.costs || [];

                    let costPath = data.costs;

                    costPath.push({
                      _id: new ObjectId().toHexString(),
                      created: new Date().toISOString(),
                      createdBy: props.loggedInUser._id,
                      name: modalData['cost.name'],
                      amount: modalData['cost.amount'],
                      stage: modalData['cost.stage'],
                      type: modalData['cost.type'],
                      description: modalData['cost.description'],
                      company: modalData['cost.company'],
                      invoiceNumber: modalData['cost.invoiceNumber'],
                    });

                    action.dataSetter(data);
                    setModalData(initialModalData);
                    props.modalHelper.hideModal();
                    break;

                  case DataActionType.EDIT_COST:
                    // Get data
                    data = cloneDeep(state);
                    for (const item of data.costs) {
                      if (item._id === modalData.cost._id) {
                        item.name = modalData['cost.name'];
                        item.amount = modalData['cost.amount'];
                        item.stage = modalData['cost.stage'];
                        item.type = modalData['cost.type'];
                        item.description = modalData['cost.description'];
                        item.company = modalData['cost.company'];
                        item.invoiceNumber = modalData['cost.invoiceNumber'];
                      }
                    }

                    action.dataSetter(data);
                    setModalData(initialModalData);
                    props.modalHelper.hideModal();
                    break;

                  case DataActionType.CREATE_JOB_CODE:
                    // Get data
                    data = cloneDeep(state);

                    // Add code group
                    data.jobCodes.push({
                      _id: new ObjectId().toHexString(),
                      created: new Date().toISOString(),
                      createdBy: props.loggedInUser._id,
                      name: modalData['jobCodes.name'],
                      startNumber: modalData['jobCodes.startNumber'] || 0,
                    });

                    action.dataSetter(data);
                    setModalData(initialModalData);
                    props.modalHelper.hideModal();
                    break;

                  case DataActionType.DELETE_JOB_CODE:
                    // Get Data
                    data = cloneDeep(state);
                    // Delete job code
                    data.jobCodes = data.jobCodes.filter(
                      (i) => i._id !== action.dataId,
                    );
                    action.dataSetter(data);
                    props.modalHelper.hideModal();
                    break;

                  case DataActionType.EDIT_JOB_CODE:
                    // Get data
                    data = cloneDeep(state);

                    for (const cg of data.jobCodes) {
                      if (cg._id === modalData.jobCode._id) {
                        cg.name = modalData['jobCode.name'];
                        cg.startNumber = modalData['jobCode.startNumber'] || 0;
                      }
                    }

                    action.dataSetter(data);
                    setModalData(initialModalData);
                    props.modalHelper.hideModal();
                    break;

                  case DataActionType.CREATE_THEME:
                    props.setLoading(true);

                    // Upload media and get mediaLink
                    const themeMediaType = CompanyThemeItemType.LOGO;
                    const themeNewMedia: MediaLink[] = [];
                    for (let i = 0; i < modalData.media.length; i++) {
                      const mime = modalData.media[i].type.split('/');
                      const mediaType =
                        mime[0] === 'image' ? MediaType.IMAGE : MediaType.VIDEO;

                      let title = '';
                      if (modalData.title) {
                        if (modalData.media.length > 1 && i > 0) {
                          title = `${modalData.title} (${i})`;
                        } else {
                          title = modalData.title;
                        }
                      }

                      const result = await props.crudAction(
                        CRUDType.UPLOAD_MEDIA,
                        DataType.MEDIA,
                        {
                          body: {
                            created: new Date(),
                            createdBy: props.loggedInUser._id,
                            title,
                          },
                          media: modalData.media[i],
                        },
                        false,
                      );

                      const mediaObj = {
                        _id: new ObjectId().toHexString(),
                        created: new Date().toISOString(),
                        createdBy: props.loggedInUser._id,
                        mediaId: result.data._id,
                        mediaType,
                        type: themeMediaType,
                      };
                      themeNewMedia.push(mediaObj);
                    }
                    data = cloneDeep(state);

                    // Add media to theme
                    if (!Array.isArray(data.theme)) {
                      data.theme = [];
                    }
                    data.theme = data.theme.concat(themeNewMedia);

                    action.dataSetter(data);
                    setModalData(initialModalData);
                    props.modalHelper.hideModal();

                    props.setLoading(false);
                    break;

                  case DataActionType.DELETE_THEME:
                    // Get Data
                    data = cloneDeep(state);
                    // Delete media
                    data.theme = data.theme.filter(
                      (med) => med._id !== action.dataId,
                    );

                    action.dataSetter(data);
                    props.modalHelper.hideModal();

                    break;

                  case DataActionType.CREATE_LEAD_SOURCE:
                    // Get data
                    data = cloneDeep(state);

                    // Add lead
                    data.leadSources.push({
                      _id: new ObjectId().toHexString(),
                      created: new Date().toISOString(),
                      createdBy: props.loggedInUser._id,
                      name: modalData['leadSource.name'],
                    });

                    action.dataSetter(data);
                    setModalData(initialModalData);
                    props.modalHelper.hideModal();
                    break;

                  case DataActionType.DELETE_LEAD_SOURCE:
                    // Get Data
                    data = cloneDeep(state);
                    // Delete lead source
                    data.leadSources = data.leadSources.filter(
                      (i) => i._id !== action.dataId,
                    );
                    action.dataSetter(data);
                    props.modalHelper.hideModal();
                    break;

                  case DataActionType.EDIT_LEAD_SOURCE:
                    // Get data
                    data = cloneDeep(state);

                    for (const cg of data.leadSources) {
                      if (cg._id === modalData.leadSource._id) {
                        cg.name = modalData['leadSource.name'];
                      }
                    }

                    action.dataSetter(data);
                    setModalData(initialModalData);
                    props.modalHelper.hideModal();
                    break;

                  case DataActionType.CREATE_STAFF_AVAILABILITY:
                    // Get data
                    data = cloneDeep(state);

                    // Add staff log
                    data.staffAvailability.push({
                      _id: new ObjectId().toHexString(),
                      created: new Date().toISOString(),
                      createdBy: props.loggedInUser._id,
                      userId: modalData['staffAvailability.userId'],
                      startDate: modalData['staffAvailability.startDate'],
                      endDate: modalData['staffAvailability.endDate'],
                      description: modalData['staffAvailability.description'],
                    });

                    action.dataSetter(data);
                    setModalData(initialModalData);
                    props.modalHelper.hideModal();
                    break;

                  case DataActionType.DELETE_STAFF_AVAILABILITY:
                    // Get Data
                    data = cloneDeep(state);
                    // Delete staff log
                    data.staffAvailability = data.staffAvailability.filter(
                      (i) => i._id !== action.dataId,
                    );
                    action.dataSetter(data);
                    props.modalHelper.hideModal();
                    break;

                  case DataActionType.EDIT_STAFF_AVAILABILITY:
                    // Get data
                    data = cloneDeep(state);

                    for (const cg of data.staffAvailability) {
                      if (cg._id === modalData.staffAvailability._id) {
                        cg.userId = modalData['staffAvailability.userId'];
                        cg.startDate = modalData['staffAvailability.startDate'];
                        cg.endDate = modalData['staffAvailability.endDate'];
                        cg.description =
                          modalData['staffAvailability.description'];
                      }
                    }

                    action.dataSetter(data);
                    setModalData(initialModalData);
                    props.modalHelper.hideModal();
                    break;

                  case DataActionType.CREATE_CUSTOM_FIELD:
                    // Get data
                    data = cloneDeep(state);

                    // Add code group
                    data.customFields.push({
                      _id: new ObjectId().toHexString(),
                      created: new Date().toISOString(),
                      createdBy: props.loggedInUser._id,
                      name: modalData['customField.name'],
                      type: modalData['customField.type'],
                      selectOptions: modalData['customField.selectOptions'],
                      isRequired: modalData['customField.isRequired'],
                    });

                    action.dataSetter(data);
                    setModalData(initialModalData);
                    props.modalHelper.hideModal();
                    break;

                  case DataActionType.DELETE_CUSTOM_FIELD:
                    // Get Data
                    data = cloneDeep(state);
                    // Delete job code
                    data.customFields = data.customFields.filter(
                      (i) => i._id !== action.dataId,
                    );
                    action.dataSetter(data);
                    props.modalHelper.hideModal();
                    break;

                  case DataActionType.EDIT_CUSTOM_FIELD:
                    // Get data
                    data = cloneDeep(state);

                    for (const cg of data.customFields) {
                      if (cg._id === modalData.customFields._id) {
                        cg.name = modalData['customField.name'];
                        cg.type = modalData['customField.type'];
                        cg.selectOptions =
                          modalData['customField.selectOptions'];
                        cg.isRequired = modalData['customField.isRequired'];
                      }
                    }

                    action.dataSetter(data);
                    setModalData(initialModalData);
                    props.modalHelper.hideModal();
                    break;
                }
              }
            }}
          >
            <Icon name={'check'} />
            {(props.showModal.action && props.showModal.action.title) ||
              'Confirm'}
          </Button>
          {(props.showModal.action.action.type === CRUDType.DELETE ||
            props.showModal.action.action.type === DataActionType.DELETE_NOTE ||
            props.showModal.action.action.type ===
              DataActionType.DELETE_MEDIA ||
            props.showModal.action.action.type ===
              DataActionType.DELETE_LOCATION ||
            props.showModal.action.action.type ===
              DataActionType.DELETE_CONTACT ||
            props.showModal.action.action.type === DataActionType.CREATE_NOTE ||
            props.showModal.action.action.type ===
              DataActionType.CREATE_MEDIA ||
            props.showModal.action.action.type ===
              DataActionType.CREATE_ATTACHMENT ||
            props.showModal.action.action.type ===
              DataActionType.CREATE_LOCATION ||
            props.showModal.action.action.type ===
              DataActionType.CREATE_CONTACT ||
            props.showModal.action.action.type ===
              DataActionType.EDIT_CONTACT ||
            props.showModal.action.action.type ===
              DataActionType.CREATE_CHECKLIST ||
            props.showModal.action.action.type ===
              DataActionType.EDIT_CHECKLIST ||
            props.showModal.action.action.type ===
              DataActionType.CREATE_CHECKLIST_TEMPLATE ||
            props.showModal.action.action.type ===
              DataActionType.EDIT_CHECKLIST_TEMPLATE ||
            props.showModal.action.action.type ===
              DataActionType.DELETE_CHECKLIST_TEMPLATE ||
            props.showModal.action.action.type ===
              DataActionType.IMPORT_CHECKLIST_TEMPLATE ||
            props.showModal.action.action.type ===
              DataActionType.IMPORT_XERO_CUSTOMER ||
            props.showModal.action.action.type ===
              DataActionType.IMPORT_QUICKBOOKS_CUSTOMER ||
            props.showModal.action.action.type ===
              DataActionType.CREATE_PAYMENT ||
            props.showModal.action.action.type ===
              DataActionType.EDIT_PAYMENT ||
            props.showModal.action.action.type ===
              DataActionType.DELETE_PAYMENT ||
            props.showModal.action.action.type ===
              DataActionType.CREATE_COST_GROUP ||
            props.showModal.action.action.type ===
              DataActionType.EDIT_COST_GROUP ||
            props.showModal.action.action.type ===
              DataActionType.DELETE_COST_GROUP ||
            props.showModal.action.action.type ===
              DataActionType.IMPORT_COST_GROUP ||
            props.showModal.action.action.type === DataActionType.CREATE_COST ||
            props.showModal.action.action.type === DataActionType.EDIT_COST ||
            props.showModal.action.action.type === DataActionType.DELETE_COST ||
            props.showModal.action.action.type ||
            props.showModal.action.action.type ===
              DataActionType.CREATE_JOB_CODE ||
            props.showModal.action.action.type ===
              DataActionType.EDIT_JOB_CODE ||
            props.showModal.action.action.type ===
              DataActionType.DELETE_JOB_CODE ||
            props.showModal.action.action.type ===
              DataActionType.CREATE_THEME ||
            props.showModal.action.action.type ===
              DataActionType.CREATE_LEAD_SOURCE ||
            props.showModal.action.action.type ===
              DataActionType.EDIT_LEAD_SOURCE ||
            props.showModal.action.action.type ===
              DataActionType.DELETE_LEAD_SOURCE ||
            props.showModal.action.action.type ===
              DataActionType.CREATE_STAFF_AVAILABILITY ||
            props.showModal.action.action.type ===
              DataActionType.EDIT_STAFF_AVAILABILITY ||
            props.showModal.action.action.type ===
              DataActionType.DELETE_STAFF_AVAILABILITY ||
            props.showModal.action.action.type ===
              DataActionType.CREATE_CUSTOM_FIELD ||
            props.showModal.action.action.type ===
              DataActionType.EDIT_CUSTOM_FIELD ||
            props.showModal.action.action.type ===
              DataActionType.DELETE_CUSTOM_FIELD) && (
            <Button
              className="ui secondary"
              type="submit"
              icon
              labelPosition={'left'}
              onClick={() => {
                setModalData(initialModalData);
                props.modalHelper.hideModal(props.showModal.showDataForm);
              }}
            >
              <Icon name={'delete'} />
              Cancel
            </Button>
          )}
        </Modal.Actions>
      )}
    </Modal>
  );
};
